import $ from 'jquery';
import * as d3 from 'd3';
import dayjs from 'dayjs';

import { Controller } from '@hotwired/stimulus';
import { MISSING_DATA_MESSAGE } from './constants';

const CONSTANTS = {
  margins: {
    l: 30,
    t: 10,
    r: 0,
    b: 20,
  },
  top_chart_height_proportion: 0.6,
  labelStandoff: 10,
};

export default class extends Controller {
  static targets = ['container', 'tooltip', 'legend'];

  static values = {
    yAxis: { type: Array, default: [] },
    xAxis: { type: Array, default: [] },
    options: { type: Object, default: {} },
    colorSet: { type: Array, default: [] },
  };

  connect() {
    let maxValue = 0;
    this.yAxisValue.forEach((el) => {
      const values = Object.values(el);
      const max = Math.max(...values);

      if (max > maxValue) {
        maxValue = max;
      }
    });

    this.leftMargin = maxValue > 100 ? 60 : CONSTANTS.margins.l;

    this.setLegendEqualWidth();

    if (!this.yAxisValue.length || !this.xAxisValue.length) {
      $(this.containerTarget).append(
        `<p class='missing-data'>${MISSING_DATA_MESSAGE}</p>`,
      );

      return;
    }

    this.prepareData();
    const { margins, labelStandoff } = CONSTANTS;

    // gets container height and width to assign it to svg dimensions
    const containerWidth = parseInt(
      d3.select(this.containerTarget).style('width'),
      10,
    );
    const containerHeight = 350;
    this.svg = d3
      .select(this.containerTarget)
      .append('svg')
      .attr('height', containerHeight)
      .attr('width', containerWidth);

    // calculates width and height (= initial - margins), which will be used to draw axes, bars etc.
    const yLabelStandoff = this.optionsValue.y_axis_label ? labelStandoff : 0;
    this.width = containerWidth - margins.r - this.leftMargin - yLabelStandoff;
    this.height = containerHeight - margins.b - margins.t;

    this.buildAxesScales();
    this.attachDataToAxes(this.chartData, this.topKeys, this.bottomKeys);

    this.mainGroup = this.svg
      .append('g')
      .attr(
        'transform',
        `translate(${this.leftMargin + yLabelStandoff}, ${margins.t})`,
      );

    this.drawYAxes();
    this.drawXAxis();

    this.barsGroup = this.mainGroup.append('g').attr('class', 'bars');
    this.barsGroup.attr('transform', `translate(${-15}, ${0})`);

    this.drawBars(this.topKeys, 'top');
    this.drawBars(this.bottomKeys, 'bottom');

    this.drawGrid();

    this.styleAxisTicks();

    this.observeContainerWidth();

    $(this.legendTargets).on('click', this.updateChart.bind(this));
  }

  // filters data to show only last 12 monthes and puts empty data if if a month is missing in the source data
  prepareData() {
    this.topKeys = this.optionsValue.top_keys;
    this.bottomKeys = this.optionsValue.bottom_keys;
    this.colorByKey = {};

    [...this.topKeys, ...this.bottomKeys].forEach((key, i) => {
      this.colorByKey[key] = this.colorSetValue[i];
    });

    const data = [];
    const currentMonthDate = dayjs().startOf('month').format('YYYY-MM-DD');

    // currentDate
    let yearAgoDate = dayjs()
      .subtract(11, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');

    while (yearAgoDate <= currentMonthDate) {
      const index = this.xAxisValue.indexOf(yearAgoDate.toString());

      if (index !== -1) {
        data.push({
          date: dayjs(yearAgoDate).format('MMM YYYY'),
          ...this.yAxisValue[index],
        });
      } else {
        const emptyData = {
          date: dayjs(yearAgoDate).format('MMM YYYY'),
        };

        this.topKeys.forEach((key) => {
          emptyData[key] = 0;
        });

        this.bottomKeys.forEach((key) => {
          emptyData[key] = 0;
        });

        data.push(emptyData);
      }

      yearAgoDate = dayjs(yearAgoDate).add(1, 'month').format('YYYY-MM-DD');
    }

    this.chartData = data;
  }

  setLegendEqualWidth() {
    let maxWidth = 0;

    $(this.element)
      .find('.chart-legend')
      .each(function () {
        const width = $(this).width();

        if (width > maxWidth) maxWidth = width;
      });

    $(this.element).find('.chart-legend').width(maxWidth);
  }

  // creates a visual scale point.
  // scaleLinear and scaleBand methods are used to transform data values into visual variables.
  // https://www.d3indepth.com/scales/
  buildAxesScales() {
    // scaleLinear creates a scale with a linear relationship between input (range) and output (domains).
    this.yTopScale = d3
      .scaleLinear()
      .range([this.height * CONSTANTS.top_chart_height_proportion, 0]);
    this.yBottomScale = d3
      .scaleLinear()
      .range([
        this.height,
        this.height * CONSTANTS.top_chart_height_proportion,
      ]);

    this.xScale = d3.scaleBand().range([0, this.width]).padding(0.2);
  }

  attachDataToAxes(data, topKeys, bottomKeys) {
    const maxTopY = this.getmax(data, topKeys);
    const maxBottomY = this.getmax(data, bottomKeys);

    // assign domains to earlier specified range
    // https://www.d3indepth.com/scales/
    this.yTopScale.domain([0, maxTopY]);
    this.yBottomScale.domain([maxBottomY, 0]);

    this.xScale.domain(data.map((d) => d.date));
  }

  getmax(data, keys) {
    return d3.max(data, (d) => {
      let max = 0;

      for (const k of keys) {
        max += d[k];
      }

      return max;
    });
  }

  yAxisTickFormat(orientation, d) {
    const tick = d3.format('d')(d);

    return tick === 0 || orientation === 'top'
      ? Number(tick).toLocaleString()
      : '-' + Number(tick).toLocaleString();
  }

  // draws top and bottom y-axis
  drawYAxes() {
    // filters all ticks to display only integers
    const yTopAxisTicks = this.yTopScale
      .ticks(6)
      .filter((tick) => Number.isInteger(tick));
    const yBottomAxisTicks = this.yBottomScale
      .ticks(4)
      .filter((tick) => Number.isInteger(tick));

    // drawing left top axis
    this.mainGroup
      .append('g')
      .attr('class', 'y-top-axis')
      .call(
        d3
          .axisLeft(this.yTopScale)
          .tickValues(yTopAxisTicks)
          .tickFormat((d) => this.yAxisTickFormat('top', d)),
      );

    // drawing left bottom axis
    this.mainGroup
      .append('g')
      .attr('class', 'y-bottom-axis')
      .call(
        d3
          .axisLeft(this.yBottomScale)
          .tickValues(yBottomAxisTicks)
          .tickFormat((d) => this.yAxisTickFormat('bottom', d)),
      );

    if (this.optionsValue.y_axis_label) {
      this.svg
        .append('g')
        .attr('class', 'y-axis-label')
        .attr('transform', 'translate(10, 80)')
        .append('text')
        .attr('style', "font-family: 'Inter'; font-size: 14px; fill: #97a3b6;")
        .attr('text-anchor', 'end')
        .attr('transform', 'rotate(-90)')
        .text(this.optionsValue.y_axis_label);
    }
  }

  // draws x-axis
  drawXAxis() {
    this.xAxis = this.mainGroup
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(-15,${this.height})`)
      .call(d3.axisBottom(this.xScale));
  }

  drawBars(keys, position) {
    const stack = d3.stack().keys(keys)(this.chartData);
    const isTop = position === 'top';

    const yScale = isTop ? this.yTopScale : this.yBottomScale;
    const colors = this.colorByKey;
    const self = this;

    this[`${position}StackedBars`] = this.barsGroup
      .selectAll(`g.${position}-stacked-bars`)
      .data(stack)
      .join(
        (enter) =>
          enter
            .append('g')
            .style('fill-opacity', 0)
            .transition()
            .duration(400)
            .style('fill-opacity', 1)
            .attr('class', `${position}-stacked-bars`)
            .attr('fill', function (d, i) {
              return colors[d.key];
            }),
        null,
        (exit) => {
          exit.transition().duration(400).style('fill-opacity', 0).remove();
        },
      )
      .selectAll('rect')
      .data((d) => d)
      .join(
        (enter) =>
          enter
            .append('rect')

            .attr('y', (d) => (position === 'top' ? yScale(d[1]) : yScale(0)))
            .attr('height', (d) => Math.abs(yScale(d[0]) - yScale(d[1])))
            .attr('x', (d) => this.xScale(d.data.date))
            .attr('width', this.xScale.bandwidth())
            .on('mouseover', function (e, d) {
              d3.select(this)
                .transition()
                .duration('50')
                .attr('opacity', '.85');

              const data = {
                date: d.data.date,
                value: d[1] - d[0],
                marker: d3.select(this.parentNode).datum().key,
              };

              self.showTooltip(this, data);
            })
            .on('mouseout', function () {
              d3.select(this).transition().duration('50').attr('opacity', '1');
              self.showTooltip(null);
            }),
        (update) =>
          update
            .transition()
            .duration(400)
            .attr('y', (d) => (position === 'top' ? yScale(d[1]) : yScale(0)))
            .attr('height', (d) => Math.abs(yScale(d[0]) - yScale(d[1])))
            .attr('x', (d) => this.xScale(d.data.date))
            .attr('width', this.xScale.bandwidth()),
        (exit) => {
          exit.transition().duration(1000).style('fill-opacity', 0).remove();
        },
      );
  }

  showTooltip(obj, data) {
    const tooltip = d3.select(this.tooltipTarget);

    if (obj) {
      const node = d3.select(obj).node();

      const color = node.parentNode.getAttribute('fill');
      const barX = node.getAttribute('x');
      const isReverse = barX > 700;

      if (isReverse) {
        tooltip.node().classList.add('tooltip--reversed');
      } else {
        tooltip.node().classList.remove('tooltip--reversed');
      }

      const label = this.optionsValue.legend_labels[data.marker] || data.marker;

      tooltip
        .select('p:first-child')
        .style('background', color)
        .text(data.value.toLocaleString());
      tooltip.select('p:last-child').text(label);

      const tooltipWidth = tooltip.node().getBoundingClientRect().width;
      const horizontalMargins = this.leftMargin + CONSTANTS.margins.r;

      const leftCoord = isReverse
        ? parseFloat(barX) +
          (parseFloat(node.getAttribute('width')) -
            parseFloat(tooltipWidth) -
            parseFloat(horizontalMargins))
        : parseFloat(barX) +
          parseFloat(node.getAttribute('width')) +
          parseFloat(horizontalMargins);

      tooltip
        .attr(
          'style',
          `top: ${node.getAttribute('y')}px; left: ${leftCoord}px;`,
        )
        .style('opacity', 1);
    } else {
      tooltip.style('opacity', 0);
    }
  }

  drawGrid() {
    // removes grids if there are already exist
    // need to clear old grid lines when data changes, such as when the user hides some bar's stacks using the legend buttons
    if (this.mainGroup.select('g.grid').node()) {
      this.mainGroup.selectAll('g.grid').remove();
    }

    // generates horizontal grid lines for bottom and top y-axes
    ['top', 'bottom'].forEach((position) => {
      const scale = position === 'top' ? this.yTopScale : this.yBottomScale;

      const ticks = scale.ticks(6).filter((tick) => Number.isInteger(tick));
      const grids = this.mainGroup
        .append('g')
        .attr('class', `grid ${position}`);

      if (ticks.length > 1) {
        grids
          .selectAll('line.horizontalGrid')
          .data(ticks)
          .enter()
          .append('line')
          .attr('x1', 0)
          .attr('x2', this.width)
          .attr('y1', (d) => scale(d))
          .attr('y2', (d) => scale(d));
      }

      // moves the grid lines down so they are displayed underneath the bars
      grids.lower();
    });
  }

  resizeChart() {
    const { margins } = CONSTANTS;

    const containerWidth = parseInt(
      d3.select(this.containerTarget).style('width'),
      10,
    );
    this.width = containerWidth - this.leftMargin - margins.r;

    // sets svg width to the new width of the parent container
    this.svg.attr('width', containerWidth);
    this.xScale.range([0, this.width]);
    this.xAxis.call(d3.axisBottom(this.xScale));

    this.drawGrid();

    this.topStackedBars
      .attr('x', (d) => this.xScale(d.data.date))
      .attr('width', this.xScale.bandwidth());

    this.bottomStackedBars
      .attr('x', (d) => this.xScale(d.data.date))
      .attr('width', this.xScale.bandwidth());

    this.styleAxisTicks();
  }

  updateChart(e) {
    const topKeys = [];
    const bottomKeys = [];
    const self = this;

    $(this.legendTargets)
      .find('input[type=checkbox]:checked')
      .each(function () {
        const value = $(this).val();

        if (self.topKeys.includes(value)) {
          topKeys.push(value);
        } else {
          bottomKeys.push(value);
        }
      });

    if (bottomKeys.length === 0) {
      this.yTopScale.range([this.height, 0]);
    } else {
      this.yTopScale.range([
        this.height * CONSTANTS.top_chart_height_proportion,
        0,
      ]);
    }

    if (topKeys.length === 0) {
      this.yBottomScale.range([this.height, 0]);
    } else {
      this.yBottomScale.range([
        this.height,
        this.height * CONSTANTS.top_chart_height_proportion,
      ]);
    }

    this.attachDataToAxes(this.chartData, topKeys, bottomKeys);
    const yBottmAxisTicks = this.yBottomScale
      .ticks(6)
      .filter((tick) => Number.isInteger(tick));
    const yTopAxisTicks = this.yTopScale
      .ticks(6)
      .filter((tick) => Number.isInteger(tick));

    this.mainGroup.select('g.y-bottom-axis').call(
      d3
        .axisLeft(this.yBottomScale)
        .tickValues(yBottmAxisTicks.length > 1 ? yBottmAxisTicks : [])
        .tickFormat((d) => this.yAxisTickFormat('bottom', d)),
    );
    this.mainGroup.select('g.y-top-axis').call(
      d3
        .axisLeft(this.yTopScale)
        .tickValues(yTopAxisTicks.length > 1 ? yTopAxisTicks : [])
        .tickFormat(d3.format('d')),
    );

    this.drawBars(bottomKeys, 'bottom');
    this.drawBars(topKeys, 'top');

    this.drawGrid();

    this.styleAxisTicks();
  }

  styleAxisTicks() {
    this.mainGroup
      .call((g) => g.selectAll('.domain').remove())
      .call((g) => g.selectAll('.axis .tick line').remove())
      .call((g) =>
        g
          .selectAll('.tick text')
          .attr(
            'style',
            "color: white; font-size: 12px; font-family: 'Poppins'; font-weight: 700;",
          ),
      )
      .call((g) =>
        g
          .selectAll('.grid line')
          .attr('stroke-dasharray', '3')
          .attr('stroke', '#5959598a'),
      );
  }

  // observes the container target to resize chart when container width changed
  // e.g. when the sidebar opens
  observeContainerWidth() {
    const observer = new ResizeObserver((_) => {
      this.resizeChart();
    });

    observer.observe(this.containerTarget);
  }
}
