import BaseChartsController from './base_charts_controller.js';
import { humanizeString } from './constants.js';

export default class extends BaseChartsController {
  static targets = ['container', 'tooltip', 'legend'];

  static values = {
    yAxis: { type: Array, default: [] },
    xAxis: { type: Array, default: [] },
    traces: { type: Array, default: [] },
    options: { type: Object, default: {} },
    colorSet: { type: Array, default: [] },
  };

  connect() {
    super.connect();

    if (!this.yAxisValue.length || !this.xAxisValue.length) {
      this.drawEmptyResult();

      return;
    }

    const data = this.chartData();

    const layout = {
      ...this.defaultLayoutConfig,
      showline: true,
      bargap: this.isDefaultOrientation ? 0.05 : 0.2,
      margin: { t: 0, b: 0, r: 0, l: 0, pad: 0 },
    };

    this.drawChart(data, layout);
  }

  chartData() {
    if (this.tracesValue.length) {
      return this.tracesValue.map((trace, index) => {
        const yAxis = this.yAxisValue.map((i) => i[trace]);

        return {
          x: this.isDefaultOrientation ? this.xAxisValue : yAxis,
          y: this.isDefaultOrientation ? yAxis : this.xAxisValue,
          initialName: trace,
          name: this.geTraceName(trace),
          visible: true,
          fill: this.optionsValue.color_filled ? 'tozeroy' : undefined,
          marker: {
            color: this.colorSetValue[index] || this.defaultColorSet[index],
          },
          stackgroup: this.isRelativeMode ? 'one' : '',
          groupnorm: this.isRelativeMode ? 'percent' : '',
          hoverinfo: 'none',
        };
      });
    } else {
      return [
        {
          x: this.xAxisValue,
          y: this.yAxisValue.map((i) => humanizeString(i)),
          orientation: this.orientation,
          fill: this.optionsValue.color_filled ? 'tozeroy' : undefined,
          marker: {
            color: this.colorSetValue[0] || this.defaultColorSet[0],
          },
          stackgroup: this.isRelativeMode ? 'one' : '',
          groupnorm: this.isRelativeMode ? 'percent' : '',
          hoverinfo: 'none',
        },
      ];
    }
  }
}
