/* eslint-disable no-useless-call */
import $ from 'jquery';
import * as d3 from 'd3';

import { Controller } from '@hotwired/stimulus';
import Plotly from 'plotly.js-dist-min';

import {
  COLORS,
  STYLES,
  DEFAULT_ORIENTATION,
  MISSING_DATA_MESSAGE,
  humanizeString,
} from './constants.js';

export default class extends Controller {
  get defaultAxesConfig() {
    return {
      gridcolor: COLORS.grid,
      zeroline: false,
      showgrid: false,
      automargin: true,
      tickfont: {
        family: STYLES.axis.label.font,
        color: COLORS.tick,
        size: STYLES.axis.label.default_size,
      },
      showticksuffix: 'all',
      ticklen: 0,
      ticklabeloverflow: 'allow',
      // fixedrange - determines whether or not this axis is zoom-able. If true, then zoom is disabled.
      fixedrange: true,
      hoverformat: this.isRelativeMode ? '.1f' : '.0f',
      title: {
        standoff: STYLES.axis.title.standoff,
        font: {
          family: STYLES.axis.title.font,
          size: STYLES.axis.title.default_size,
          color: COLORS.axis_title,
        },
      },
    };
  }

  get labelAlias() {
    const labelAlias = this.optionsValue?.label_alias || {};

    const allLabelAlias = {};

    this.xAxisValue?.forEach((el) => {
      if (typeof el === 'string')
        allLabelAlias[el] = labelAlias[el] || humanizeString(el);
    });

    return allLabelAlias;
  }

  get yAxisTicksuffix() {
    const providedTicksuffix = this.optionsValue?.y_axis_tick_suffix;

    return (
      providedTicksuffix ||
      (this.isRelativeMode && this.isDefaultOrientation ? '%' : '')
    );
  }

  get xAxisTicksuffix() {
    const providedTicksuffix = this.optionsValue?.x_axis_tick_suffix;

    return (
      providedTicksuffix ||
      (this.isRelativeMode && !this.isDefaultOrientation ? '%' : '')
    );
  }

  get defaultLayoutConfig() {
    const yAxisTitle = this.optionsValue?.y_axis_title;
    const xAxisTitle = this.optionsValue?.x_axis_title;

    return {
      barmode: 'stack',
      plot_bgcolor: COLORS.chart_bg,
      paper_bgcolor: COLORS.chart_bg,
      margin: { t: 16, b: 0, r: 0, l: 0, pad: 8 },
      showlegend: false,
      barnorm: this.isRelativeMode ? 'percent' : '',
      yaxis: {
        ...this.defaultAxesConfig,
        showgrid: true,
        title: {
          ...this.defaultAxesConfig.title,
          standoff: yAxisTitle ? STYLES.axis.title.standoff : 0,
          text: yAxisTitle || '',
        },
        ticksuffix: this.yAxisTicksuffix,
        labelalias: this.labelAlias,
      },
      xaxis: {
        ...this.defaultAxesConfig,
        showgrid: false,
        tickformat: ',d',
        nticks: 20,
        title: {
          ...this.defaultAxesConfig.title,
          standoff: xAxisTitle ? STYLES.axis.title.standoff : 0,
          text: xAxisTitle || '',
        },
        labelalias: this.labelAlias,
        ticksuffix: this.xAxisTicksuffix,
      },
      hovermode: this.isDefaultOrientation ? undefined : 'closest',
    };
  }

  get isRelativeMode() {
    return !!this.optionsValue?.relative_mode;
  }

  get orientation() {
    return this.optionsValue?.orientation || DEFAULT_ORIENTATION;
  }

  get isDefaultOrientation() {
    return this.orientation === DEFAULT_ORIENTATION;
  }

  connect() {
    if (this.hasLegendTarget) {
      this.setLegendEqualWidth();

      $(this.legendTargets).on('click', this.hideTrace.bind(this));
    }

    if (this.optionsValue.legend_order) {
      this.tracesValue = this.optionsValue.legend_order;
    }
  }

  disconnect() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    if (this.hasContainerTarget) {
      Plotly.purge(this.containerTarget);
    }
  }

  setLegendEqualWidth() {
    let maxWidth = 0;

    $(this.legendTargets).each(function () {
      const width = $(this).width();

      if (width > maxWidth) maxWidth = width;
    });

    $(this.legendTargets).width(maxWidth);
  }

  geTraceName(trace) {
    const label = this.optionsValue?.legend_alias?.[trace] || trace;

    return humanizeString(label);
  }

  onHoverBar(data) {
    const point = data.points[0];
    const tooltip = d3.select(this.tooltipTarget);

    const color = point.data.marker.color;
    const isReverse = point.bbox.x1 > 700;

    if (isReverse) {
      tooltip.node().classList.add('tooltip--reversed');
    } else {
      tooltip.node().classList.remove('tooltip--reversed');
    }

    const initialValue = this.isDefaultOrientation
      ? point.data.y[point.pointIndex]
      : point.data.x[point.pointIndex];
    const value = initialValue || point.value;
    const label = point.data.name || point.label;

    tooltip
      .select('p:first-child')
      .style('background', color)
      .text(value.toLocaleString());
    tooltip.select('p:last-child').text(label);

    const tooltipWidth = tooltip.node().getBoundingClientRect().width;
    const tooltipHeight = tooltip.node().getBoundingClientRect().height;

    const topCoordinate = this.isDefaultOrientation
      ? point.bbox.y0 - tooltipHeight * 0.5
      : point.bbox.y0;

    const leftOffset = this.isDefaultOrientation ? 0 : 10;
    const leftCoordinate = isReverse
      ? point.bbox.x0 - tooltipWidth - leftOffset
      : point.bbox.x1 - leftOffset;

    tooltip
      .attr('style', `top: ${topCoordinate}px; left: ${leftCoordinate}px;`)
      .style('opacity', 1);
  }

  onUnhoverBar() {
    d3.select(this.tooltipTarget).style('opacity', 0);
  }

  drawEmptyResult() {
    $(this.containerTarget).append(
      `<p class='missing-data'>${MISSING_DATA_MESSAGE}</p>`,
    );
  }

  drawChart(data, layout, addOnHoverHandler = true) {
    Plotly.newPlot(this.containerTarget, data, layout, {
      displayModeBar: false,
      responsive: true,
    });

    if (addOnHoverHandler) {
      this.containerTarget.on('plotly_hover', this.onHoverBar.bind(this));

      $(this.containerTarget).on('mouseleave', this.onUnhoverBar.bind(this));
    }

    this.observeContainerWidth();
  }

  resizeChart() {
    const self = this;
    if ($(this.containerTarget).has('.plotly')) {
      Plotly.Plots.resize(this.containerTarget).then(() => {
        self.onPlotUpdate.call(self);
      });
    }
  }

  observeContainerWidth() {
    let delay;
    const self = this;

    this.resizeObserver = new ResizeObserver(() => {
      clearTimeout(delay);
      delay = setTimeout(() => {
        self.resizeChart.call(self);
      }, 100);
    });

    this.resizeObserver.observe(this.containerTarget);
  }

  hideTrace(e) {
    const target = $(e.currentTarget).find('input[type=checkbox]');
    const trace = target.val() || '';
    const container = this.containerTarget;
    const self = this;

    const traceObject = container.data.find(
      (item) => item.initialName === trace,
    );

    const index = container.data.indexOf(traceObject);
    const visible = target.prop('checked');

    Plotly.restyle(container, { visible }, [index]).then(() => {
      self.onPlotUpdate.call(self);
    });
  }
}
