import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['eyeIcons'];

  connect() {
    this.passwordInput = $(this.element).find('input[type=password]');
  }

  toggleFieldType(e) {
    $(this.passwordInput).attr('type', (_, attr) =>
      attr === 'password' ? 'text' : 'password',
    );
    $(this.eyeIconsTargets).toggle();
  }
}
