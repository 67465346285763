import { Controller } from '@hotwired/stimulus';
import JustValidate from 'just-validate';

export default class extends Controller {
  static targets = ['form'];

  static values = {
    validation: Array,
  };

  connect() {
    this.validate = new JustValidate(this.formTarget, {
      validateBeforeSubmitting: true,
    });

    this.validationValue.forEach((validation) => {
      switch (validation['type']) {
        case 'group': {
          this.validate.addRequiredGroup(
            validation['id'],
            validation['error_message'] || 'You should select at least one option',
            validation['config'] || {},
          );
          break;
        }
        case 'field': {
          this.validate.addField(
            validation['id'],
            validation['error_message'] || 'The field is required',
            validation['rules'] || [ { rule: 'required'} ],
            validation['config'] || {},
          );
          break;
        }
      }
    });
  }

  submitForm() {
    this.validate.destroy();
    const form = this.formTarget;

    setTimeout(() => {
      form.requestSubmit();
    }, 100);
  }
}
