import BaseChartsController from './base_charts_controller.js';
import { STYLES, COLORS } from './constants.js';

// Required data for heatmap chart

// title - chart title

// xAxis - array of labels to be displayed on the x-axis
// e.g. ["Product1", "Product2", "Product3"]

// yAxis - array of labels to be displayed on the y-axis,
// e.g. ["Location1", "Location2"]

// zAxis - array of the values of x point on the y-axis
// e.g. for Location1: Product1 = 10, Product2 = 20, Product3 = 30; for Location2: Product1 = 100, Product2 = 200, Product3 = 300;
// zAxis = [[10, 20, 30], [100, 200, 300]]

export default class extends BaseChartsController {
  static targets = ['container'];

  static values = {
    title: { type: String, default: '' },
    xAxis: { type: Array, default: [] },
    yAxis: { type: Array, default: [] },
    zAxis: { type: Array, default: [] },
    colorSet: { type: Array, default: [] },
    options: { type: Object, default: {} },
  };

  connect() {
    if (!this.hasContainerTarget) {
      return;
    }

    const colorSet = this.colorSetValue || this.defaultColorSet;
    this.yAxis = this.yAxisValue.reverse();
    this.xAxis = this.xAxisValue;

    const data = [
      {
        z: this.zAxisValue,
        x: this.xAxis,
        y: this.yAxis,
        type: 'heatmap',
        xgap: 10,
        ygap: 10,
        hoverongaps: false,
        showscale: false,
        colorscale: [
          [0, colorSet[0]],
          [0.3, colorSet[1]],
          [0.6, colorSet[2]],
          [0.9, colorSet[3]],
          [1, colorSet[4]],
        ],
      },
    ];

    const layout = this.configLayout();

    this.drawChart(data, layout, false);
  }

  createAnnotations() {
    const annotations = [];

    this.yAxis.forEach((y, yIndex) => {
      this.xAxis.forEach((x, xIndex) => {
        const annotation = {
          xref: 'x1',
          yref: 'y1',
          x,
          y,
          text: (this.zAxisValue[yIndex][xIndex] || 0).toLocaleString(),
          font: {
            family: STYLES.annotations.family,
            size: STYLES.annotations.size,
            color: COLORS.annotation,
          },
          showarrow: false,
        };

        annotations.push(annotation);
      });
    });

    return annotations;
  }

  configLayout() {
    return {
      plot_bgcolor: COLORS.chart_bg,
      paper_bgcolor: COLORS.chart_bg,
      annotations: this.createAnnotations(),
      hovermode: false,
      yaxis: {
        fixedrange: true,
        automargin: true,
        showticklabels: false,
        ticks: '',
        showgrid: false,
      },
      xaxis: {
        side: 'top',
        fixedrange: true,
        automargin: true,
        showticklabels: false,
        ticks: '',
        showgrid: false,
      },
      margin: {
        autoexpand: true,
        l: 0,
        r: 0,
        t: 0,
        b: 0,
      },
      showlegend: false,
    };
  }
}
