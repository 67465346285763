import { Controller } from '@hotwired/stimulus';

const SEARCHING_DELAY = 200;

export default class extends Controller {
  static targets = ['searchBar', 'form'];

  connect() {
    let timerId;

    // submit form after user stop typing with delay
    this.searchBarTarget.addEventListener('keyup', () => {
      clearTimeout(timerId);
      timerId = setTimeout(this.submitForm.bind(this), SEARCHING_DELAY);
    });
  }

  clearSearchQuery() {
    this.searchBarTarget.value = '';

    this.submitForm();
  }

  submitForm() {
    this.formTarget.requestSubmit();
  }
}
