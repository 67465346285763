import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['arrow'];
  static classes = ['arrowOpen'];

  connect() {}

  toggleRow(e) {
    const index = $(e.currentTarget).data('index');

    $(this.element)
      .find(`tr[data-target="${index}"]`)
      .delay(5)
      .toggleClass('hidden');

    if (this.hasArrowOpenClass) {
      $(this.arrowTargets)
        .filter(`[data-target="${index}"]`)
        .toggleClass(this.arrowOpenClass);
    }
  }
}
