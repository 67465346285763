// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from './application';

// Register each controller with Stimulus
import controllers from './**/*_controller.js';

import { SmartTableControllers } from '@smarter-contracts/pulse-stimulus';

import Flatpickr from 'stimulus-flatpickr';

import { Tabs } from 'tailwindcss-stimulus-components';

controllers.forEach((controller) => {
  application.register(controller.name, controller.module['default']);
});

SmartTableControllers.forEach((controller) => {
  application.register(controller.name, controller.module);
});

application.register('flatpickr', Flatpickr);
application.register('tabs', Tabs);
