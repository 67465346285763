import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['form'];
  static values = {
    notifyUrl: String,
    subscriptionName: String,
  };

  connect() {
    this.subscribeChannelToRecieveNotification();
  }

  submitForm(e) {
    setTimeout(() => {
      this.subscribeChannelToRecieveNotification();
    });
  }

  subscribeChannelToRecieveNotification() {
    const notifyUrl = this.notifyUrlValue;
    const subscriptionName = this.subscriptionNameValue;

    $(document).trigger(
      'notifications_controller.state',
      function (notificationsController) {
        notificationsController.subscribeChannelToRecieveNotification(
          subscriptionName,
          notifyUrl,
        );
      },
    );
  }
}
