export const COLORS = {
  line: '#ff000000',
  grid: '#5959598a',
  tick: '#fff',
  axis_title: '#97a3b6',
  chart_bg: 'transparent',
  legend: {
    inactive: {
      text: '#ffffff87',
      bg: '#82828438',
    },
    active: {
      text: '#ffffff',
    },
  },
  annotation: '#FFFFFFCC',
};

export const STYLES = {
  axis: {
    label: {
      font: 'Inter',
      default_size: 12,
      small_size: 10,
    },
    title: {
      font: 'Inter',
      default_size: 14,
      small_size: 12,
      standoff: 16,
    },
  },
  annotations: {
    family: 'Inter',
    size: 18,
  },
};

export const DATE_FORMAT = 'YYYY-MM';
export const DATE_RANGE_TICK = {
  month: 'M1',
  week: 86400000 * 7,
  day: 86400000,
};

export const MAX_DAYS_IN_MONTH = 31;
export const MISSING_DATA_MESSAGE = 'Data is missing';

export const DEFAULT_ORIENTATION = 'v';
export const DEFAULT_COLOR_SET = [
  '#63538D',
  '#965692',
  '#C55986',
  '#E7686E',
  '#EE8251',
  '#F2A93C',
  '#F4E773',
  '#a4e74fe0',
  '#1BD67C',
  '#50D7CF',
  '#4FA1ED',
  '#496FF8',
];

export function humanizeString(str) {
  return str
    .replace(/(^\w)/g, (g) => g[0].toUpperCase())
    .replace(/([-_]\w)/g, (g) => ' ' + g[1].toUpperCase())
    .trim();
}
