import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';
import $ from 'jquery';

const AVAILABLE_SUBSCRIPTIONS = ['ExportDataChannel'];
export default class extends Controller {
  connect() {
    this.checkActiveSubscriptions();

    // create action "notifications_controller.state" to access this controller from other controllers
    $(document).on(
      'notifications_controller.state',
      function (_, callback) {
        // eslint-disable-next-line n/no-callback-literal
        callback(this);
      }.bind(this),
    );
  }

  checkActiveSubscriptions() {
    const activeSubscriptions = localStorage.getItem('activeSubscriptions');

    if (activeSubscriptions) {
      JSON.parse(activeSubscriptions).forEach((s) => {
        if (AVAILABLE_SUBSCRIPTIONS.includes(s.name)) {
          this.subscribeChannelToRecieveNotification(s.name, s.url);
        }
      });
    }
  }

  isSubscribed(subscriptionName) {
    const subscriptionsList = consumer.subscriptions.subscriptions.filter((i) =>
      i.identifier.includes(subscriptionName),
    );

    return subscriptionsList.length > 0;
  }

  // save subscription name in the local storage to be able to re-create subscription if page was reloaded and subscription ended
  saveSubscriptionToActiveList(name, url) {
    const activeSubscriptions = localStorage.getItem('activeSubscriptions');

    const subscriptionsList = activeSubscriptions
      ? JSON.parse(activeSubscriptions)
      : [];
    const isAlreadyExist = subscriptionsList.find((s) => s.name === name);

    if (isAlreadyExist) return;

    localStorage.setItem(
      'activeSubscriptions',
      JSON.stringify([...subscriptionsList, { name, url }]),
    );
  }

  // remove inactive(which already received the data) subscription name from the local storage
  removeSubscriptionFromActiveList(name) {
    const activeSubscriptions = localStorage.getItem('activeSubscriptions');
    const subscriptionsList = activeSubscriptions
      ? JSON.parse(activeSubscriptions)
      : [];

    localStorage.setItem(
      'activeSubscriptions',
      JSON.stringify(subscriptionsList.filter((s) => s.name !== name)),
    );
  }

  subscribeChannelToRecieveNotification(subscriptionName, notifyUrl) {
    const removeSubscriptionFromActiveList =
      this.removeSubscriptionFromActiveList;
    if (this.isSubscribed(subscriptionName)) {
      return;
    }

    this.saveSubscriptionToActiveList(subscriptionName, notifyUrl);

    consumer.subscriptions.create(subscriptionName, {
      connected() {
        console.log('___connected');
      },

      disconnected() {
        console.log('__disconnected');
      },

      // TODO: probably need to make it more generic
      received(data) {
        const jobId = data[0];
        const userId = data[1];
        const filename = data[2];

        fetch(
          `${notifyUrl}?job_id=${jobId}&user_id=${userId}&filename=${filename}`,
        );

        removeSubscriptionFromActiveList(subscriptionName);
      },
    });
  }
}
