import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['hideable', 'icon'];
  static classes = ['open', 'iconOpen'];

  static values = {
    clickOutside: { type: Boolean, default: true },
  };

  connect() {
    if (this.clickOutsideValue) {
      document.addEventListener('click', this.handleClickOutside.bind(this));
    }
  }

  handleClickOutside(e) {
    const isVisible = this.hasOpenClass
      ? $(this.hideableTargets).hasClass(this.openClass)
      : $(this.hideableTargets).is(':visible');

    if (!this.element.contains(e.target) && isVisible) {
      this.toggleTargets(e);
    }
  }

  get toggleClass() {
    return this.hasOpenClass ? this.openClass : 'hidden';
  }

  toggleTargets(e) {
    if (this.hasOpenClass) {
      $(this.hideableTargets).toggleClass(this.openClass);
    } else {
      $(this.hideableTargets).delay(5).slideFadeToggle(100);
    }

    if (this.hasIconTarget && this.hasIconOpenClass) {
      $(this.iconTarget).toggleClass(this.iconOpenClass);
    }
  }
}
