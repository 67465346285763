import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['options', 'title'];

  static values = {
    ignoreLabelUpdate: { type: Boolean, default: false },
  };

  connect() {
    if (!this.ignoreLabelUpdateValue) {
      $(this.optionsTarget)
        .find('input')
        .on('click', this.updateSelectorTitle.bind(this));
    }
  }

  updateSelectorTitle() {
    const selected = $(this.optionsTarget).find('input[type="radio"]:checked');

    if (selected.length)
      $(this.titleTarget).text($(selected).next('label').text());
      $(this.optionsTarget).delay(5).slideFadeToggle(100);
    }
}
