import BaseChartsController from './base_charts_controller.js';
import { humanizeString } from './constants.js';

export default class extends BaseChartsController {
  static targets = ['container'];

  static values = {
    donut: { type: Boolean, default: false },
    labels: Array,
    x: { type: Array, default: [] },
    y: { type: Array, default: [] },
    id: String,
    colorSet: { type: Array, default: [] },
  };

  connect() {
    if (!this.yValue.length || !this.xValue.length) {
      this.drawEmptyResult();

      return;
    }

    const data = [
      {
        values: this.xValue,
        labels: this.yValue.map(humanizeString),
        initialLabels: this.yValue,
        marker: {
          colors: this.colorSetValue,
        },
        direction: 'clockwise',
        sort: true,
        hole: 0.5,
        type: 'pie',
        hoverinfo: 'none',
        textinfo: 'none',
      },
    ];

    const layout = {
      ...this.defaultLayoutConfig,
    };

    this.drawChart(data, layout);
  }
}
