import { Controller } from '@hotwired/stimulus';
import Plotly from 'plotly.js-dist-min';

export default class extends Controller {
  static targets = ['draw', 'animate'];

  // Will create data visualisations using https://plotly.com/ inside element marked up as the Controller target

  // USAGE :

  // Initialize this controller and provide the URL to lazy load the circles data
  //
  //      e.g hash of consent data suitable for populating bubbles
  //
  // <div id="bubbles-main" data-controller="bubbles" data-bubbles-url="<%= bubbles_path %>">
  //
  // Add a target dov where you want the plot to appear
  //
  //    <div data-bubbles-target="draw" ....
  //
  connect() {
    this.url = this.data.get('url');
    this.height = this.data.get('height');
    this.width = this.data.get('width');

    console.log('Create plot in elem ' + this.drawTarget);

    fetch(this.url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.demo(this.drawTarget, data);
        // this.plot(this.drawTarget, data);
        // this.animate(this.animateTarget, data);
        // this.example(this.animateTarget, data);
      });
  }

  demo(drawTarget, data) {
    const demoData = [
      {
        values: [15328],
        labels: ['Marketing &amp; General Communications'],
        hoverinfo: 'none',
        textinfo: 'label+value',
        type: 'pie',
        marker: {
          colors: ['rgba(62,32,220,0.75)'],
        },
      },
    ];

    const layout = {
      font: {
        family:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        size: 18,
        color: '#7f7f7f',
      },
      height: 900,
      width: 900,
      showlegend: false,
      paper_bgcolor: '#151a27',
      xaxis: { title: '', visible: false, showticklabels: false },
      yaxis: { title: '', visible: false, showticklabels: true },
    };

    const config = { responsive: true, displayModeBar: false };

    Plotly.newPlot(drawTarget, demoData, layout, config);
  }

  plot(drawTarget, circles) {
    const sizes = [];

    const texts = [];
    const xs = [];
    const ys = [];

    let colour = 0;
    const colors = [];

    let x = 0;
    let y = 0;
    const gap = this.height / circles.length;

    const tone = 360 / circles.length;

    for (const point of circles) {
      texts.push(point.sector + '<br>Consents: ' + point.count);
      sizes.push(point.size);
      colour = tone + colour;
      colors.push('rgb(' + colour / 2 + ',' + colour / 2 + ', ' + colour + ')');
      x = x + gap;
      xs.push(x);
      y = y + gap / 2;
      ys.push(point.count);
    }

    console.log(xs);

    const trace1 = {
      x: xs,
      y: ys,
      text: texts,
      mode: 'markers',
      marker: {
        color: colors, // ['rgb(93, 164, 214)', 'rgb(255, 144, 14)', 'rgb(44, 160, 101)', 'rgb(255, 65, 54)'],
        size: sizes,
      },
      hoverinfo: 'text',
    };

    const data = [trace1];

    const layout = {
      title: 'Consent Requests by Product',
      showlegend: false,
      height: this.height,
      width: this.width,
      plot_bgcolor: '#071431',
      paper_bgcolor: '#071431',
      xaxis: { title: 'x-label', visible: false, showticklabels: false },
      yaxis: { title: '', visible: true, showticklabels: true },
    };

    const config = { responsive: true, displayModeBar: false };

    Plotly.newPlot(drawTarget, data, layout, config);
  }

  /*    email,year,pop,continent,lifeExp,gdpPercap
0: {color: '#ff7205', consent_count: 2, name: 'Hackathons', count: 2, distance: 128.57142857142858, …}
1: {color: '#ff7201', consent_count: 3, name: 'Daily Edit emails', count: 3, distance: 257.14285714285717, …}
2: {color: '#ff7203', consent_count: 7, name: 'Gated content Quarterly Briefings', count: 7, distance: 385.7142857142858, …}
3: {color: '#ff7200', consent_count: 2, name: 'Conferences', count: 2, distance: 514.2857142857143, …}
4: {color: '#ff7202', consent_count: 3, name: 'Gated content Other', count: 3, distance: 642.8571428571429, …}
5: {color: '#ff7206', consent_count: 1, name: 'Marketing Updates', count: 1, distance: 771.4285714285714, …}
6: {color: '#ff7204', consent_count: 2, name: 'Gated content eBooks', count: 2, distance: 900, …}
        */
  // animate(drawTarget, data) {
  //
  //     const config = this.config();
  //
  //     // Create a lookup table to sort and regroup the columns of data,
  //     // first by year, then by continent:
  //     var lookup = {};
  //
  //     function getData(product, continent) {
  //         var byYear, trace;
  //         if (!(byYear = lookup[product])) {
  //             ;
  //             byYear = lookup[product] = {};
  //         }
  //         // If a container for this year + continent doesn't exist yet,
  //         // then create one:
  //         if (!(trace = byYear[continent])) {
  //             trace = byYear[continent] = {
  //                 x: [],
  //                 y: [],
  //                 id: [],
  //                 text: [],
  //                 marker: {size: []}
  //             };
  //         }
  //         return trace;
  //     }
  //
  //
  //     // Go through each row, get the right trace, and append the data:
  //     for (const datum of data) {
  //
  //         var trace = getData(datum.year, datum.continent);
  //         trace.text.push(datum.country);
  //         trace.id.push(datum.country);
  //         trace.x.push(datum.name);
  //         trace.y.push(datum.consent_count);
  //         trace.marker.size.push(datum.pop);
  //     }
  //
  //     // Get the group names:
  //     var years = Object.keys(lookup);
  //
  //     // In this case, every year includes every continent, so we
  //     // can just infer the continents from the *first* year:
  //     var firstYear = lookup[years[0]];
  //     var continents = Object.keys(firstYear);
  //
  //     // Create the main traces, one for each continent:
  //     var traces = [];
  //     for (var i = 0; i < continents.length; i++) {
  //         for (const datum of data) {
  //         var data = firstYear[continents[i]];
  //         // One small note. We're creating a single trace here, to which
  //         // the frames will pass data for the different years. It's
  //         // subtle, but to avoid data reference problems, we'll slice
  //         // the arrays to ensure we never write any new data into our
  //         // lookup table:
  //         traces.push({
  //             name: continents[i],
  //             x: data.x.slice(),
  //             y: data.y.slice(),
  //             id: data.id.slice(),
  //             text: data.text.slice(),
  //             mode: 'markers',
  //             marker: {
  //                 size: data.marker.size.slice(),
  //                 sizemode: 'area',
  //                 sizeref: 200000
  //             }
  //         });
  //     }
  //
  //     // Create a frame for each year. Frames are effectively just
  //     // traces, except they don't need to contain the *full* trace
  //     // definition (for example, appearance). The frames just need
  //     // the parts the traces that change (here, the data).
  //     var frames = [];
  //     for (i = 0; i < years.length; i++) {
  //         frames.push({
  //             name: years[i],
  //             data: continents.map(function (continent) {
  //                 return getData(years[i], continent);
  //             })
  //         })
  //     }
  //
  //     // Now create slider steps, one for each frame. The slider
  //     // executes a plotly.js API command (here, Plotly.animate).
  //     // In this example, we'll animate to one of the named frames
  //     // created in the above loop.
  //     var sliderSteps = [];
  //     for (i = 0; i < years.length; i++) {
  //         sliderSteps.push({
  //             method: 'animate',
  //             label: years[i],
  //             args: [[years[i]], {
  //                 mode: 'immediate',
  //                 transition: {duration: 300},
  //                 frame: {duration: 300, redraw: false},
  //             }]
  //         });
  //     }
  //
  //     var layout = {
  //         plot_bgcolor: "#071431",
  //         paper_bgcolor:"#071431",
  //         width: 1000,
  //         xaxis: {
  //             title: 'Product',
  //             range: [30, 85]
  //         },
  //         yaxis: {
  //             title: 'Consent Requests',
  //             type: 'log'
  //         },
  //         hovermode: 'closest',
  //         // We'll use updatemenus (whose functionality includes menus as
  //         // well as buttons) to create a play button and a pause button.
  //         // The play button works by passing `null`, which indicates that
  //         // Plotly should animate all frames. The pause button works by
  //         // passing `[null]`, which indicates we'd like to interrupt any
  //         // currently running animations with a new list of frames. Here
  //         // The new list of frames is empty, so it halts the animation.
  //         updatemenus: [{
  //             x: 0,
  //             y: 0,
  //             yanchor: 'top',
  //             xanchor: 'left',
  //             showactive: false,
  //             direction: 'left',
  //             type: 'buttons',
  //             pad: {t: 87, r: 10},
  //             buttons: [{
  //                 method: 'animate',
  //                 args: [null, {
  //                     mode: 'immediate',
  //                     fromcurrent: true,
  //                     transition: {duration: 300},
  //                     frame: {duration: 500, redraw: false}
  //                 }],
  //                 label: 'Play'
  //             }, {
  //                 method: 'animate',
  //                 args: [[null], {
  //                     mode: 'immediate',
  //                     transition: {duration: 0},
  //                     frame: {duration: 0, redraw: false}
  //                 }],
  //                 label: 'Pause'
  //             }]
  //         }],
  //         // Finally, add the slider and use `pad` to position it
  //         // nicely next to the buttons.
  //         sliders: [{
  //             pad: {l: 130, t: 55},
  //             currentvalue: {
  //                 visible: true,
  //                 prefix: 'Year:',
  //                 xanchor: 'right',
  //                 font: {size: 20, color: '#666'}
  //             },
  //             steps: sliderSteps
  //         }]
  //     };
  //
  //     // Create the plot:
  //     Plotly.newPlot(drawTarget, {
  //         data: traces,
  //         layout: layout,
  //         frames: frames,
  //         config
  //     });
  // }

  example(drawTarget, data) {
    const config = this.config();

    Plotly.d3.csv(
      'https://raw.githubusercontent.com/plotly/datasets/master/gapminderDataFiveYear.csv',
      function (err, data) {
        console.log(err.stack);
        // Create a lookup table to sort and regroup the columns of data,
        // first by year, then by continent:
        const lookup = {};

        function getData(product, continent) {
          let byYear, trace;
          if (!(byYear = lookup[product])) {
            byYear = lookup[product] = {};
          }
          // If a container for this year + continent doesn't exist yet,
          // then create one:
          if (!(trace = byYear[continent])) {
            trace = byYear[continent] = {
              x: [],
              y: [],
              id: [],
              text: [],
              marker: { size: [] },
            };
          }
          return trace;
        }

        // Go through each row, get the right trace, and append the data:
        for (let i = 0; i < data.length; i++) {
          const datum = data[i];
          const trace = getData(datum.year, datum.continent);
          trace.text.push(datum.country);
          trace.id.push(datum.country);
          trace.x.push(datum.lifeExp);
          trace.y.push(datum.gdpPercap);
          trace.marker.size.push(datum.pop);
        }

        // Get the group names:
        const years = Object.keys(lookup);
        // In this case, every year includes every continent, so we
        // can just infer the continents from the *first* year:
        const firstYear = lookup[years[0]];
        const continents = Object.keys(firstYear);

        // Create the main traces, one for each continent:
        const traces = [];
        for (let i = 0; i < continents.length; i++) {
          const data = firstYear[continents[i]];
          // One small note. We're creating a single trace here, to which
          // the frames will pass data for the different years. It's
          // subtle, but to avoid data reference problems, we'll slice
          // the arrays to ensure we never write any new data into our
          // lookup table:
          traces.push({
            name: continents[i],
            x: data.x.slice(),
            y: data.y.slice(),
            id: data.id.slice(),
            text: data.text.slice(),
            mode: 'markers',
            marker: {
              size: data.marker.size.slice(),
              sizemode: 'area',
              sizeref: 200000,
            },
          });
        }

        // Create a frame for each year. Frames are effectively just
        // traces, except they don't need to contain the *full* trace
        // definition (for example, appearance). The frames just need
        // the parts the traces that change (here, the data).
        const frames = [];
        for (let i = 0; i < years.length; i++) {
          frames.push({
            name: years[i],
            data: continents.map(function (continent) {
              return getData(years[i], continent);
            }),
          });
        }

        // Now create slider steps, one for each frame. The slider
        // executes a plotly.js API command (here, Plotly.animate).
        // In this example, we'll animate to one of the named frames
        // created in the above loop.
        const sliderSteps = [];
        for (let i = 0; i < years.length; i++) {
          sliderSteps.push({
            method: 'animate',
            label: years[i],
            args: [
              [years[i]],
              {
                mode: 'immediate',
                transition: { duration: 300 },
                frame: { duration: 300, redraw: false },
              },
            ],
          });
        }

        const layout = {
          plot_bgcolor: '#071431',
          paper_bgcolor: '#071431',
          width: 1000,
          xaxis: {
            title: 'Product',
            range: [30, 85],
          },
          yaxis: {
            title: 'Consent Requests',
            type: 'log',
          },
          hovermode: 'closest',
          // We'll use updatemenus (whose functionality includes menus as
          // well as buttons) to create a play button and a pause button.
          // The play button works by passing `null`, which indicates that
          // Plotly should animate all frames. The pause button works by
          // passing `[null]`, which indicates we'd like to interrupt any
          // currently running animations with a new list of frames. Here
          // The new list of frames is empty, so it halts the animation.
          updatemenus: [
            {
              x: 0,
              y: 0,
              yanchor: 'top',
              xanchor: 'left',
              showactive: false,
              direction: 'left',
              type: 'buttons',
              pad: { t: 87, r: 10 },
              buttons: [
                {
                  method: 'animate',
                  args: [
                    null,
                    {
                      mode: 'immediate',
                      fromcurrent: true,
                      transition: { duration: 300 },
                      frame: { duration: 500, redraw: false },
                    },
                  ],
                  label: 'Play',
                },
                {
                  method: 'animate',
                  args: [
                    [null],
                    {
                      mode: 'immediate',
                      transition: { duration: 0 },
                      frame: { duration: 0, redraw: false },
                    },
                  ],
                  label: 'Pause',
                },
              ],
            },
          ],
          // Finally, add the slider and use `pad` to position it
          // nicely next to the buttons.
          sliders: [
            {
              pad: { l: 130, t: 55 },
              currentvalue: {
                visible: true,
                prefix: 'Year:',
                xanchor: 'right',
                font: { size: 20, color: '#666' },
              },
              steps: sliderSteps,
            },
          ],
        };

        // Create the plot:
        Plotly.newPlot(drawTarget, {
          data: traces,
          layout,
          frames,
          config,
        });
      },
    );
  }

  config() {
    return {
      responsive: true,
      displayModeBar: false,
      displaylogo: false,
    };
  }
}
