import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import dayjs from 'dayjs';

const DISPLAY_DATE_PICKER_DATE_FORMAT = 'DD MMM YY';
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD hh:mm:ss';
const DEFAULT_DATE_PICKER_DATE_FORMAT = 'MM/DD/YYYY';

export default class extends Controller {
  static targets = ['form'];

  static values = {
    url: String,
    from: String,
    to: String,
    page: String,
  };

  connect() {
    // event listener to set "Custom" checkbox value to "custom" before form submit
    $(this.formTarget).on('submit', function () {
      $('input#from_custom').val('custom');

      return true;
    });

    // event listener to prevent date range calendars from closing when clicking outside those calendars
    // only when custom option is selected
    $(this.formTarget).on('mousedown', function (ev) {
      if ($('input#from_custom').is(':checked')) {
        ev.stopPropagation();
      }
    });

    // hide date range calendars on click date picker options except of custom
    $('input[type=radio]:not(#from_custom)').on('click', function () {
      $('.daterangepicker').hide();
    });

    this.configCustomDateRange();
    this.setInputDateValue();
  }

  resetForm() {
    this.formTarget.reset();
    this.setInputDateValue();
  }

  saveDateRangeToSessionStorage(dateRangeFrom, dateRangeTo) {
    const dateRangeKey = `${this.pageValue}_date_range`;

    const dateRange = {
      to: dateRangeTo,
      from: dateRangeFrom,
    };

    sessionStorage.setItem(dateRangeKey, JSON.stringify(dateRange));
  }

  getDateRangeFromSessionStorage() {
    const dateRangeKey = `${this.pageValue}_date_range`;
    const dateRangeValue = sessionStorage.getItem(dateRangeKey);

    if (!dateRangeValue) {
      const defaultFromValue = this.fromValue
        ? dayjs(this.fromValue, DEFAULT_DATE_FORMAT).format(
            DEFAULT_DATE_PICKER_DATE_FORMAT,
          )
        : dayjs().format(DEFAULT_DATE_PICKER_DATE_FORMAT);
      const defaultToValue = dayjs(this.toValue, DEFAULT_DATE_FORMAT).format(
        DEFAULT_DATE_PICKER_DATE_FORMAT,
      );

      this.saveDateRangeToSessionStorage(defaultFromValue, defaultToValue);

      return {
        to: defaultToValue,
        from: defaultFromValue,
      };
    }

    const parsedDateRange = JSON.parse(dateRangeValue);

    return {
      to: parsedDateRange.to,
      from: parsedDateRange.from,
    };
  }

  findSelectedValue() {
    const selectedOption = $('.date-picker-items').find('input:checked');

    return selectedOption[0]?.value;
  }

  setInputDateValue(value) {
    let selectedValue = value;
    let from = dayjs().format(DEFAULT_DATE_PICKER_DATE_FORMAT);
    let to = dayjs().format(DEFAULT_DATE_PICKER_DATE_FORMAT);

    if (!selectedValue) {
      selectedValue = this.findSelectedValue();
    }

    switch (selectedValue) {
      case '30d': {
        from = dayjs()
          .subtract(1, 'month')
          .format(DEFAULT_DATE_PICKER_DATE_FORMAT);

        break;
      }
      case '60d': {
        from = dayjs()
          .subtract(2, 'month')
          .format(DEFAULT_DATE_PICKER_DATE_FORMAT);
        break;
      }
      case '90d': {
        from = dayjs()
          .subtract(3, 'month')
          .format(DEFAULT_DATE_PICKER_DATE_FORMAT);
        break;
      }
      case 'ytd': {
        from = dayjs()
          .subtract(1, 'year')
          .format(DEFAULT_DATE_PICKER_DATE_FORMAT);

        break;
      }
      case 'current': {
        from = null;

        break;
      }

      default: {
        const dateRangeValue = this.getDateRangeFromSessionStorage();
        from = dateRangeValue.from;
        to = dateRangeValue.to;
      }
    }

    const fromDisplayFormat = from
      ? dayjs(from, DEFAULT_DATE_PICKER_DATE_FORMAT).format(
          DISPLAY_DATE_PICKER_DATE_FORMAT,
        )
      : '-';
    const toDisplayFormat = dayjs(to, DEFAULT_DATE_PICKER_DATE_FORMAT).format(
      DISPLAY_DATE_PICKER_DATE_FORMAT,
    );

    $('input[name="date_range"]').val(
      `${fromDisplayFormat} to ${toDisplayFormat}`,
    );
  }

  onOptionSelect(e) {
    this.setInputDateValue(e.target.value);
  }

  configCustomDateRange() {
    const dateRangeValue = this.getDateRangeFromSessionStorage();

    $('#from_custom').daterangepicker({
      opens: 'left',
      autoUpdateInput: true,
      autoApply: true,
      linkedCalendars: false,
      startDate: dateRangeValue.from,
      endDate: dateRangeValue.to,
      parentEl: $('#date-picker__calendars'),
      opened: true,
      expanded: true,
    });

    $('#from_custom').on('apply.daterangepicker', (ev, picker) => {
      const fromValue = picker.startDate.format(
        DISPLAY_DATE_PICKER_DATE_FORMAT,
      );
      const toValue = picker.endDate.format(DISPLAY_DATE_PICKER_DATE_FORMAT);

      $('input[name="date_range"]').val(fromValue + ' to ' + toValue);

      this.saveDateRangeToSessionStorage(
        picker.startDate.format(DEFAULT_DATE_PICKER_DATE_FORMAT),
        picker.endDate.format(DEFAULT_DATE_PICKER_DATE_FORMAT),
      );

      picker.show();
    });
  }
}
