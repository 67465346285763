import { Controller } from '@hotwired/stimulus';
import { PULSE_API_ROOT } from './constants';
export default class extends Controller {
  static targets = ['token'];

  connect() {}

  clearToken(event) {
    const settingId = event.currentTarget.dataset.settingId;

    if (confirm('Are you sure you want to clear this Token?')) {
      fetch(`${PULSE_API_ROOT}/integrations/settings/${settingId}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content'),
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          // Handle success - remove the token div and replace with a new input form
          const tokenDiv = this.tokenTarget;
          const templateContent = document.getElementById(
            'token-input-template',
          ).content;
          tokenDiv.replaceWith(templateContent.cloneNode(true));
        })
        .catch((error) => {
          // Handle error
          console.error('There was a problem deleting the token:', error);
        });
    }
  }
}
