import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['email', 'button'];

  connect() {
    const button = this.buttonTarget;

    $(this.emailTarget).on('input', function () {
      if ($(this).val() !== '') {
        $(button).removeAttr('disabled');
      } else {
        $(button).attr('disabled', true);
      }
    });
  }
}
