import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['options', 'searchBar'];

  connect() {
    if (!this.hasOptionsTarget) return;

    $(this.searchBarTarget).on('input', this.search.bind(this));
    this.initialDisplayProp = $(this.optionsTarget).css('display');
  }

  search(e) {
    const searchQuery = $(this.searchBarTarget).val().toLowerCase();
    const initialDisplayProp = this.initialDisplayProp;

    $(this.optionsTargets).each(function () {
      const option = $(this).is('input') ? $(this) : $(this).find('input');
      const optionValue = $(option).attr('value').toLowerCase();

      if (optionValue.includes(searchQuery)) {
        $(this).css('display', initialDisplayProp);
      } else {
        $(this).css('display', 'none');
      }
    });
  }

  clearSearchQuery() {
    this.searchBarTarget.value = '';
    const initialDisplayProp = this.initialDisplayProp;

    $(this.optionsTargets).each(function () {
      $(this).css('display', initialDisplayProp);
    });
  }
}
