import { Controller } from '@hotwired/stimulus';
import { get, post } from '@rails/request.js';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    $('html, body').animate({
      scrollTop: $(document).height() - $(window).height(),
    });
  }

  // Helpers for dealing with turbo requests that are not automatically
  // POST such as link_to an index routes

  getTurboSteam(event) {
    event.preventDefault();
    get(event.target.href, {
      contentType: 'text/vnd.turbo-stream.html',
      responseKind: 'turbo-stream',
    });

    $('html, body').animate({
      scrollTop: $(document).height() - $(window).height(),
    });
  }

  postTurboSteam(event) {
    event.preventDefault();
    post(event.target.href, {
      contentType: 'text/vnd.turbo-stream.html',
      responseKind: 'turbo-stream',
    });

    $('html, body').animate({
      scrollTop: $(document).height() - $(window).height(),
    });
  }
}
