import { Controller } from '@hotwired/stimulus';
import { PULSE_API_ROOT } from './constants';

export default class extends Controller {
  static targets = ['collectionPoint', 'collectionPointId', 'permission'];

  connect() {
    if (this.hasPermissionTarget) {
      this.updatePermissions();
    }

    // Listen for the custom event
    this.element.addEventListener(
      'nested-form:section-added',
      this.updateCollectionPointId.bind(this),
    );
  }

  disconnect() {
    // Cleanup: remove the event listener
    this.element.removeEventListener(
      'nested-form:section-added',
      this.updateCollectionPointId.bind(this),
    );
  }

  updatePermissions() {
    if (this.permissionTargets.length > 0) {
      fetch(
        `${PULSE_API_ROOT}/permissions.json?collection_point_id=${this.collectionPointTarget.value}&select_options=true`,
      )
        .then((response) => response.json())

        .then((data) => {
          this.permissionTargets.forEach((permissionTarget) => {
            const selectedOption =
              permissionTarget.options[permissionTarget.selectedIndex];

            // Don't update if the User has already selected a Permission (prompt is not 'Select Permission')
            if (selectedOption.text === 'Select Permission') {
              permissionTarget.replaceChildren(); // Clear current options
              data.forEach((item) => {
                const option = document.createElement('option');
                option.text = item[0]; // short_description
                option.value = item[1]; // id
                permissionTarget.add(option);
              });
            }
          });
        });
    }
  }

  updateCollectionPointId(event) {
    // Assuming you can access the required value at this.collectionPointTarget.value
    const newCollectionPointId = this.collectionPointTarget.value;

    this.collectionPointIdTargets.forEach((target) => {
      // Find any blanks hidden fields and update value to currently selected Collection Point
      if (
        target.value === null ||
        target.value === undefined ||
        target.value === ''
      ) {
        target.value = newCollectionPointId;
      }
    });
  }
}
