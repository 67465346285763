import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['signOutButton'];

  connect() {
    if (this.hasSignOutButtonTarget) {
      this.signOutButtonTarget.addEventListener('click', this.clearSessionStorage);
    }
  }

  disconnect() {
    if (this.hasSignOutButtonTarget) {
      this.signOutButtonTarget.removeEventListener('click', this.clearSessionStorage);
    }
  }

  clearSessionStorage() {
    sessionStorage.clear();
  }
}
