import BaseChartsController from './base_charts_controller.js';

// Required data for stacked bar chart

// title - chart title

// traces (for stacked bar chart) - array

// xAxis - array of string/number/date to be displayed on the x-axis
// e.g. ["Product1", "Product2", "Product3"]

// yAxis - array of number or array of Hashes if traces provided
// e.g. ["Location1", "Location2"] or
// [{ trace1: value, trace2: value }, { trace1: value, trace2: value }]

export default class extends BaseChartsController {
  static targets = ['container'];

  static values = {
    data: { type: Object, default: {} },
    colorSet: { type: Array, default: [] },
    options: { type: Object, default: {} },
  };

  humanizeLabel(str) {
    return str
      .replace(/(^\w)/g, (g) => g[0].toUpperCase())
      .replace(/([-_]\w)/g, (g) => ' ' + g[1].toUpperCase())
      .trim();
  }

  connect() {
    const traces = Object.keys(this.dataValue);

    const data = traces.map((trace, i) => {
      return {
        x: [this.dataValue[trace]],
        text: [this.dataValue[trace]],
        name: trace,
        orientation: 'h',
        marker: {
          color: this.colorSetValue[i] || this.defaultColorSet[i],
          width: 1,
        },
        type: 'bar',
        hoverinfo: 'none',
      };
    });

    const layout = {
      ...this.defaultLayoutConfig,
      xaxis: {
        showticklabels: false,
        showline: false,
        showgrid: false,
        showdividers: false,
        zeroline: false,
        fixedrange: true,
      },
      yaxis: {
        showticklabels: false,
        showline: false,
        showgrid: false,
        showdividers: false,
        fixedrange: true,
      },
      legend: {
        font: {
          color: 'white',
          size: 10,
        },
        orientation: 'h',
        x: 0,
        y: 0,
        xanchor: 'left',
      },
      margin: { t: 0, b: 0, r: 0, l: 0 },

      showlegend: true,
    };

    this.drawChart(data, layout, false);
  }
}
