import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['options', 'block', 'selectedCount'];

  static values = {
    border: String,
  };

  connect() {
    $(this.optionsTargets).on('change', this.handleOptionChange.bind(this));
  }

  handleNoneValue(e) {
    const value = $(e.target).attr('value');

    if (!$(e.target).is(':checked')) return;

    if (value === 'none') {
      $(this.optionsTargets)
        .filter(":not([value='none']):checked")
        .trigger('click');
    } else {
      $(this.optionsTargets).filter("[value='none']:checked").trigger('click');
    }
  }

  handleOptionChange(e) {
    this.handleNoneValue(e);

    const selected = $(this.optionsTargets).filter(':checked').length;

    if (selected > 0) {
      $(this.selectedCountTarget).text(`(${selected})`);
      $(this.blockTarget).css('border-color', 'var(--active-blue-clr)');
    } else {
      $(this.selectedCountTarget).text('');
      $(this.blockTarget).css('border-color', this.borderValue);
    }
  }
}
