import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

const ICONS = {
  open_full_screen_icon: 'fa-down-left-and-up-right-to-center',
  exit_full_screen_icon: 'fa-up-right-and-down-left-from-center',
};

export default class extends Controller {
  static targets = ['content', 'button'];
  static classes = ['activeContent'];

  connect() {
    if (this.hasButtonTarget) {
      $(this.buttonTarget).on('click', this.toggleFullScreenMode.bind(this));
    }
  }

  topOffset() {
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  toggleFullScreenMode() {
    if ($(this.contentTarget).hasClass(this.activeContentClass)) {
      this.unsetFullScreenMode();
    } else {
      this.setFullScreenMode();
    }

    this.toggleIcon();
  }

  toggleIcon() {
    $(this.buttonTarget).find('i').toggleClass(ICONS.open_full_screen_icon);
    $(this.buttonTarget).find('i').toggleClass(ICONS.exit_full_screen_icon);
  }

  setFullScreenMode() {
    this.initialTopOffset = this.topOffset();

    window.scrollTo(0, 0);
    $('body').addClass(this.activeContentClass);

    $(this.contentTarget).addClass(this.activeContentClass);
  }

  unsetFullScreenMode() {
    $(this.contentTarget).removeClass(this.activeContentClass);
    $('body').removeClass(this.activeContentClass);

    window.scrollTo({
      top: this.initialTopOffset,
    });
  }
}
