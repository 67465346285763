import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['options', 'trigger'];

  static values = {
    border: { type: String, default: 'var(--hex-input-border)' },
  };

  connect() {
    console.log('check - ', this.hasTriggerTarget);

    if (this.hasTriggerTarget) {
      $(this.optionsTarget)
        .find('input')
        .on('change', this.handleSelectedOption.bind(this));
    }
  }

  handleSelectedOption(e) {
    const selected = $(this.optionsTarget).find(
      'input:not[name=select_all]:checked',
    ).length;

    if (selected > 0) {
      $(this.element).find('[data-count]').text(`(${selected})`);
      $(this.triggerTarget).css('border-color', 'var(--active-blue-clr)');
    } else {
      $(this.element).find('[data-count]').text('');
      $(this.triggerTarget).css('border-color', this.borderValue);
    }
  }
}
