import NestedForm from 'stimulus-rails-nested-form';

export default class extends NestedForm {
  connect() {
    super.connect();
  }

  add(event) {
    super.add(event);

    // Trigger a custom event after the section has been added
    this.element.dispatchEvent(
      new CustomEvent('nested-form:section-added', {
        bubbles: true,
        detail: { addedElement: this.targetTarget },
      }),
    );
  }

  /*
    Over ride the library method because we want to indicate to the User
    that the nested record is only marked for deletion - actually removed from DB when Submit clicked
  */
  remove(event) {
    event.preventDefault();

    // @ts-ignore
    const wrapper = event.target.closest(this.wrapperSelectorValue);

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove();
    } else {
      wrapper.style.display = 'none';

      const input = wrapper.querySelector("input[name*='_destroy']");
      input.value = '1';
    }
  }
}
