import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['options', 'toggleAll'];

  connect() {
    $(this.optionsTargets)
      .find('input[type="checkbox"]')
      .on('change', (e) => {
        if (!e.currentTarget.checked && this.toggleAllTarget.checked) {
          $(this.toggleAllTarget).prop('checked', false);
        }
      });
  }

  toggleAllOptions(e) {
    const isChecked = e.currentTarget.checked;

    if (isChecked) {
      $(this.optionsTargets)
        .find('input[type="checkbox"]:not(:checked)')
        .trigger('click');
    } else {
      $(this.optionsTargets)
        .find('input[type="checkbox"]:checked')
        .trigger('click');
    }
  }
}
