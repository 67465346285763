import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = ['description', 'message'];

  connect() {
    Turbo.setConfirmMethod((message, element) => {
      const dialog = this.element;
      const description = element.dataset.turboConfirmDescription || '';

      this.messageTarget.textContent = message;

      if (description) {
        this.descriptionTarget.innerHTML = description;
      }

      dialog.showModal();

      return new Promise((resolve) => {
        dialog.addEventListener(
          'close',
          () => {
            resolve(dialog.returnValue === 'confirm');
          },
          { once: true },
        );
      });
    });
  }
}
