import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['control', 'colorWrapper'];

  connect() {
    $(this.controlTargets).on('change', this.toggle.bind(this));
  }

  toggle(e) {
    const wrapper = $(this.colorWrapperTargets)
      .filter(`[data-override-attribute=${e.target.value}]`)
      .first();

    if (wrapper) {
      $(wrapper).toggle();

      if (e.target.checked) {
        $(wrapper).find('input[type=color]').removeAttr('disabled');
        $(wrapper).find('input[type=hidden]').attr('disabled', true);
      } else {
        $(wrapper).find('input[type=color]').attr('disabled', true);
        $(wrapper).find('input[type=hidden]').removeAttr('disabled');
      }
    }
  }
}
