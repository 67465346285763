import { Controller } from '@hotwired/stimulus';
import JustValidate from 'just-validate';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['stepContent', 'step', 'next', 'prev', 'submit'];

  static classes = ['activeStep'];
  static values = {
    step: { type: Number, default: 0 },
    validationStep: { type: Number, default: -1 },
    stepsCount: Number,
    stepsValidation: Array,
  };

  connect() {
    this.validate = new JustValidate('#multistep_form', {
      validateBeforeSubmitting: true,
    });

    $(this.nextTarget).on('click', this.goNext.bind(this));
    $(this.prevTarget).on('click', this.goBack.bind(this));
    $(this.submitTarget).on('click', this.submitForm.bind(this));
  }

  stepValueChanged(val) {
    this.validationStepValue = val;

    this.showStepContent();
    this.checkButtonsVisibility();

    if (val === this.stepsCountValue - 1) {
      this.dispatch('lastStep');
    }
  }

  validationStepValueChanged(val, prev) {
    if (this.hasStepsValidationValue) {
      if (val > prev) {
        this.stepsValidationValue[val]?.forEach((validation) => {
          switch (validation['type']) {
            case 'group': {
              this.validate.addRequiredGroup(
                validation['id'],
                validation['error_message'],
                validation['config'],
              );
              break;
            }
            case 'field': {
              this.validate.addField(
                validation['id'],
                validation['rules'],
                validation['config'],
              );
              break;
            }
          }
        });
      } else {
        this.stepsValidationValue[prev]?.forEach((validation) => {
          switch (validation['type']) {
            case 'group': {
              this.validate.removeGroup(validation['id']);
              break;
            }
            case 'field': {
              this.validate.removeField(validation['id']);
              break;
            }
          }
        });
      }
    }
  }

  goBack() {
    this.stepValue = this.stepValue - 1;
  }

  goNext() {
    const self = this;

    this.validate.revalidate().then((isValid) => {
      console.log(isValid);
      if (isValid) {
        self.stepValue = self.stepValue + 1;
      }
    });
  }

  submitForm() {
    this.validate.destroy();

    setTimeout(() => {
      document.getElementById('multistep_form').requestSubmit();
    }, 100);
  }

  checkButtonsVisibility() {
    $(this.submitTarget).css(
      'display',
      this.stepValue + 1 === this.stepsCountValue ? 'block' : 'none',
    );
    $(this.nextTarget).css(
      'display',
      this.stepValue + 1 !== this.stepsCountValue ? 'block' : 'none',
    );
    $(this.prevTarget).css('display', this.stepValue > 0 ? 'block' : 'none');
  }

  showStepContent() {
    this.stepContentTargets.forEach((stepContent, index) => {
      const step = this.stepTargets[index];

      if (index === this.stepValue) {
        $(stepContent).show();
        if (this.hasActiveStepClass) step?.classList?.add(this.activeStepClass);
      } else {
        $(stepContent).hide();
        if (this.hasActiveStepClass)
          step?.classList?.remove(this.activeStepClass);
      }
    });
  }
}
