/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';
import { MISSING_DATA_MESSAGE } from './constants';
import $ from 'jquery';

import env from 'env';

// TODO: remove these variables after solving the issue with ENV vars
const PULSE_MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1Ijoia21hcmNoYW5rYSIsImEiOiJjbDVobmRxYmIwYXgzM2pvdHpvMmJ0MXFmIn0.h1brGpkdx6F_WQ_1JIimrA';
const PULSE_MAPBOX_STYLE_URL =
  'mapbox://styles/kmarchanka/clesbeqpl000y01qd6d3kwlmw';

const DEFAULT_SHAPE_COLOR_RGB = [0, 0, 0, 0];

const MAP_TYPES = {
  world: 'world',
  europe: 'european_union',
  uk: 'uk',
};

const MAP_COLORS_SCALE = {
  banded: 'banded',
  scaled: 'scaled',
};

export default class extends Controller {
  static targets = ['container', 'tooltip'];

  static values = {
    data: Array,
    options: { type: Object, default: {} },
    colorSet: { type: Array, default: [] },
    bandedScale: { type: Array, default: [] },
  };

  observeContainerWidth() {
    let delay;
    const self = this;

    this.resizeObserver = new ResizeObserver(() => {
      clearTimeout(delay);
      delay = setTimeout(() => {
        // eslint-disable-next-line no-useless-call
        self.resizeMap.call(self);
      }, 100);
    });

    this.resizeObserver.observe(this.containerTarget);
  }

  hexToRgb(hex) {
    return hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => '#' + r + r + g + g + b + b,
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));
  }

  connect() {
    console.log(
      'DEBUG env.PULSE_MAPBOX_STYLE_URL - ',
      env.PULSE_MAPBOX_STYLE_URL,
    );
    console.log(
      'DEBUG env.PULSE_MAPBOX_ACCESS_TOKEN - ',
      env.PULSE_MAPBOX_ACCESS_TOKEN,
    );

    if (!this.dataValue.length) {
      $(this.containerTarget).append(
        `<p class='missing-data'>${MISSING_DATA_MESSAGE}</p>`,
      );

      return;
    }

    this.mapType = MAP_TYPES.world;
    this.colorScale = MAP_COLORS_SCALE.banded;
    this.isValueHash = typeof this.dataValue[0].value === 'object';

    mapboxgl.accessToken = PULSE_MAPBOX_ACCESS_TOKEN;
    this.renderMap();

    // this.addSearchBar();
  }

  disconnect() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  mapLayerFilters() {
    switch (this.mapType) {
      case MAP_TYPES.europe:
        return [
          'all',
          ['==', 'region', 'Europe'],
          [
            '!in',
            'name_en',
            'Russia',
            'Belarus',
            'Ukraine',
            'Svalbard and Jan Mayen',
            'Albania',
            'Serbia',
            'Azerbaijan',
            'Georgia',
            'Liechtenstein',
            'Turkey',
            'Switzerland',
            'Bosnia and Herzegovina',
            'Montenegro',
            'North Macedonia',
            'Kosovo',
          ],
        ];
      case MAP_TYPES.uk:
        return ['all', ['==', 'name_en', 'United Kingdom']];
      default:
        return [];
    }
  }

  mapStyleConfig() {
    switch (this.mapType) {
      case MAP_TYPES.europe:
        return {
          center: [10, 42],
          zoom: 4,
          maxZoom: 5,
          minZoom: 3,
          renderWorldCopies: true,
        };
      case MAP_TYPES.uk:
        return {
          center: [-5, 52],
          zoom: 5,
          maxZoom: 7,
          minZoom: 4,
          renderWorldCopies: true,
        };
      default:
        return {
          zoom: 0,
          renderWorldCopies: false,
        };
    }
  }

  updateMapStyle(e) {
    const type = e.target.value;

    if (Object.values(MAP_TYPES).includes(type)) {
      this.map.remove();
      this.mapType = type;
      this.renderMap();
    }
  }

  updateMapColors(e) {
    const scale = e.target.value;

    if (Object.values(MAP_COLORS_SCALE).includes(scale)) {
      this.map.remove();
      this.colorScale = scale;
      this.renderMap();
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  resizeMap() {
    this.map.resize();
    this.map.setZoom(0);
  }

  renderMap() {
    const mapStyleConfig = this.mapStyleConfig();
    const map = new mapboxgl.Map({
      container: this.containerTarget,
      style: PULSE_MAPBOX_STYLE_URL,
      maxZoom: 4,
      minZoom: 0,
      ...mapStyleConfig,
      projection: 'mercator',
    });

    this.map = map;

    map.addControl(
      new mapboxgl.NavigationControl({ showCompass: false }),
      'top-right',
    );

    // disable map rotation using right click + drag and scroll zoom
    map.scrollZoom.disable();
    map.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    map.touchZoomRotate.disableRotation();

    map.on('load', this.onMapLoaded.bind(this));
    map.on('render', () => map.resize());
  }

  addLayers() {
    const matchExpression = this.calculateColorScaling();

    this.map.addLayer({
      id: 'cfh', // country-fills-hover
      type: 'fill',
      source: 'countries',
      'source-layer': 'country_boundaries',
      layout: {},
      paint: {
        'fill-color': '#FFFFFF',
        'fill-opacity': 0.1,
      },
      filter: ['all', ['==', 'name', '']],
    });

    if (matchExpression.length > 3) {
      this.map.addLayer({
        id: 'countries-join',
        type: 'fill',
        source: 'countries',
        'source-layer': 'country_boundaries',
        paint: {
          'fill-color': matchExpression,
        },
      });
    }

    if (this.mapType !== MAP_TYPES.world) {
      const filters = this.mapLayerFilters();
      this.map.setFilter('country-boundary-bg', filters);
      this.map.setFilter('countries-join', filters);
      this.map.setFilter('cfh', filters);
    }
  }

  generateRgbColor(rgbArray) {
    return `rgba(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]}, ${rgbArray[3]})`;
  }

  generateBandedColor() {
    const matchExpression = ['match', ['get', 'iso_3166_1']];

    this.dataValue.forEach((item) => {
      const value =
        (this.isValueHash ? item.value[this.optionsValue.key] : item.value) ||
        0;
      let bandIndex = 0;

      this.bandedScaleValue.forEach((band, index) => {
        let lessThen = true;
        let moreThen = true;

        if (band[0]) {
          moreThen = value >= band[0];
        }

        if (band[1]) {
          lessThen = value <= band[1];
        }

        if (lessThen && moreThen) {
          bandIndex = index;
        }
      });

      const rgbColor = this.hexToRgb(this.colorSetValue[bandIndex]);
      const color = this.generateRgbColor([...rgbColor, 1]);

      if (item.country_code) {
        matchExpression.push(item.country_code, color);
      }
    }, this);

    matchExpression.push(this.generateRgbColor(DEFAULT_SHAPE_COLOR_RGB));

    return matchExpression;
  }

  generateScaledColor() {
    const mainRGBColor = this.hexToRgb(this.colorSetValue[0]);

    const matchExpression = ['match', ['get', 'iso_3166_1']];
    const maxValue = this.isValueHash
      ? 0
      : this.dataValue.reduce((max, item) => {
          const value =
            (this.isValueHash
              ? item.value[this.optionsValue.key]
              : item.value) || 0;

          return value > max ? value : max;
        }, 0);

    this.dataValue.forEach((item) => {
      let opacity = 0;

      if (this.isValueHash) {
        const key = this.optionsValue.key;
        opacity = (item.value[key] || 0) / (maxValue || 1);
      } else {
        opacity = (item.value || 0) / maxValue;
      }

      const color = this.generateRgbColor([
        ...mainRGBColor,
        opacity.toFixed(1),
      ]);

      if (item.country_code) {
        matchExpression.push(item.country_code, color);
      }
    }, this);

    matchExpression.push(this.generateRgbColor(DEFAULT_SHAPE_COLOR_RGB));

    return matchExpression;
  }

  calculateColorScaling() {
    if (this.colorScale === MAP_COLORS_SCALE.banded) {
      return this.generateBandedColor();
    } else {
      return this.generateScaledColor();
    }
  }

  onMapLoaded() {
    this.map.addSource('countries', {
      type: 'vector',
      url: 'mapbox://mapbox.country-boundaries-v1',
    });

    this.addLayers();

    this.map.on('mousemove', function (e) {
      const features = this.queryRenderedFeatures(e.point, {
        layers: ['country-boundary-bg'],
      });

      if (features.length) {
        this.getCanvas().style.cursor = 'pointer';
        this.setFilter('cfh', ['==', 'name', features[0].properties.name]);
      } else {
        this.setFilter('cfh', ['==', 'name', '']);
        this.getCanvas().style.cursor = '';
      }
    });

    const popupModal = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    this.map.on('mousemove', this.showTooltipOnHover.bind(this, popupModal));

    this.observeContainerWidth();
  }

  showTooltipOnHover(popupModal, event) {
    const features = this.map.queryRenderedFeatures(event.point, {
      layers: ['country-boundary-bg'],
    });
    this.map.getCanvas().style.cursor = features.length ? 'pointer' : '';

    // Remove things if no feature was found.
    if (!features.length) {
      popupModal.remove();

      return;
    }

    const countryName = features[0].properties.name_en;
    const countryCode = features[0].properties.iso_3166_1;

    const countryData = this.dataValue.find(
      (item) => item.country_code === countryCode,
    );

    if (!countryData) {
      return;
    }

    const tooltipInsights = this.isValueHash
      ? Object.entries(this.optionsValue.tooltip_labels).map((item) => {
          return [(countryData.value[item[0]] || 0).toLocaleString(), item[1]];
        })
      : [[countryData.value.toLocaleString(), this.optionsValue.tooltip_label]];

    const tooltipBody = this.tooltipTarget.cloneNode(true);

    $(tooltipBody).removeClass('hidden');
    $(tooltipBody)
      .find('img')
      .attr('src', `https://flagcdn.com/${countryCode.toLowerCase()}.svg`);
    $(tooltipBody).find('h2').text(countryName);

    tooltipInsights.forEach((tooltip) => {
      const tooltipValue = $(`<span></span>`).text(tooltip[0]);
      const tooltipLabel = $(
        "<span class='ml-2 font-text-regular-sm text-color-util-tint-80'></span>",
      ).text(tooltip[1]);
      const tooltipWrapper = $('<p></p>').append(tooltipValue, tooltipLabel);

      $(tooltipBody).find('.tooltip-insights').append(tooltipWrapper);
    });

    popupModal
      .setLngLat(event.lngLat)
      .setHTML(tooltipBody.innerHTML)
      .addTo(this.map);
  }

  addSearchBar() {
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl,
    });

    if (!$('#geocoder').children().length) {
      $('#geocoder').append(geocoder.onAdd(this.map));
    }
  }
}
