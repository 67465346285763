import dayjs from 'dayjs';
import * as d3 from 'd3';

import BaseChartsController from './base_charts_controller.js';

import {
  MAX_DAYS_IN_MONTH,
  DATE_FORMAT,
  DATE_RANGE_TICK,
} from './constants.js';

export default class extends BaseChartsController {
  get defaultTimeSeriesLayoutConfig() {
    return {
      ...this.defaultLayoutConfig,
      updatemenus: this.showTimeRange
        ? this.buildTimeSeriesRangeButtons()
        : undefined,
      // showline: true,
      // mirror: 'ticks',
      yaxis: {
        ...this.defaultLayoutConfig.yaxis,
        minor: {
          tickmode: 'auto',
        },
        nticks: 10,
        // only integer
        tickformat: ',d',
        ticklabeloverflow: 'allow',
        hoverformat: this.isRelativeMode ? '.1f' : '.0f',
      },
      xaxis: {
        ...this.defaultLayoutConfig.xaxis,
        title: undefined,
        tickformat: undefined,
        hoverformat: undefined,
        type: 'date',
        dtick: 'M1',
        ticklabeloverflow: 'allow',
        rangeslider: this.showTimeRange
          ? { range: [this.xAxis[0], ...this.xAxis.slice(-1)] }
          : undefined,
      },
    };
  }

  get showTimeRange() {
    return !!this.optionsValue.time_range;
  }

  connect() {
    super.connect();

    this.xAxis = this.xAxisValue.map((i) =>
      dayjs(i).startOf('month').format(DATE_FORMAT),
    );
  }

  // direction = 'x' OR 'y'
  getAxisConfigByDirection(direction) {
    return {
      ...this.defaultAxesConfig,
      hoverformat: undefined,
      showgrid: direction === 'y',
      title: {
        ...this.defaultAxesConfig.title,
        text: this.optionsValue?.[`${direction}_axis_label`] || '',
      },
      ticksuffix:
        this.optionsValue?.[`${direction}_axis_tick_suffix`] ||
        (this.isRelativeMode &&
          (direction === 'x'
            ? !this.isDefaultOrientation
            : this.isDefaultOrientation))
          ? '%'
          : '',
    };
  }

  buildTimeSeriesRangeButtons() {
    return [
      {
        buttons: [
          {
            args: [
              { x: [this.xAxis] },
              {
                'xaxis.dtick': DATE_RANGE_TICK.month,
                'xaxis.range': this.getDateRange('reset'),
              },
            ],
            label: 'All',
            method: 'update',
          },
          {
            args: [
              { x: [this.groupDatesByRange('week').x] },
              {
                'xaxis.dtick': DATE_RANGE_TICK.week,
                'xaxis.range': this.getDateRange('90d'),
              },
            ],
            label: '90 Days',
            method: 'update',
          },
          {
            args: [
              { x: [this.groupDatesByRange('week').x] },
              {
                'xaxis.dtick': DATE_RANGE_TICK.week,
                'xaxis.range': this.getDateRange('60d'),
              },
            ],
            label: '60 Days',
            method: 'update',
          },
          {
            args: [
              { x: [this.groupDatesByRange('day').x] },
              {
                'xaxis.dtick': DATE_RANGE_TICK.day,
                'xaxis.range': this.getDateRange('30d'),
              },
            ],
            label: '30 Days',
            method: 'update',
          },
        ],
        direction: 'left',
        pad: { r: 0, t: 0 },
        showactive: true,
        active: 0,
        type: 'buttons',
        x: 0,
        xanchor: 'left',
        y: 1.4,
        yanchor: 'top',
        bordercolor: '#ffffff00',
        font: {
          family: 'Poppins',
          color: 'white',
          size: 14,
        },
      },
    ];
  }

  // range = month OR week OR day
  groupDatesByRange(range) {
    const xAxis = this.xAxisValue.map((i) =>
      dayjs(i).startOf(range).format(DATE_FORMAT),
    );
    const uniqXAxis = xAxis.filter(
      (v, index) => !dayjs(v).isSame(xAxis[index + 1]),
    );

    return { x: uniqXAxis };
  }

  // detaPickerValue can be 30d, 60d, 90d, or all
  getDateRange(detaPickerValue) {
    if (detaPickerValue === 'reset') {
      return [this.xAxis[0], ...this.xAxis.slice(-1)];
    }

    const dates = this.xAxisValue
      .filter((i) => {
        let limit;
        switch (detaPickerValue) {
          case '30d': {
            limit = dayjs().subtract(1, 'month').startOf('month');
            break;
          }
          case '60d': {
            limit = dayjs().subtract(2, 'month').startOf('month');
            break;
          }
          case '90d': {
            limit = dayjs().subtract(3, 'month').startOf('month');
            break;
          }
          default: {
            limit = dayjs().subtract(1, 'year').startOf('month');
            break;
          }
        }
        const diff = dayjs(i).diff(limit, 'day');

        return diff > 0;
      })
      .map((i) => dayjs(i).format(DATE_FORMAT));

    return [dates[0], ...dates.slice(-1)];
  }

  // getAxisWithoutDublicates(xAxis, yAxis) {
  //   const duplicatedIndexes = [];
  //   const uniqX = xAxis.filter((v, index) => {
  //     const isSame = moment(v).isSame(xAxis[index + 1]);

  //     if (isSame) {
  //       duplicatedIndexes.push(index);
  //     }

  //     return !isSame;
  //   })

  //   const uniqY =  duplicatedIndexes.length ? yAxis.filter((_, index) => {
  //     return !duplicatedIndexes.includes(index);
  //   }) : yAxis;

  //   return { x: uniqX, y: uniqY }
  // }

  calculateBarWidthAndOffsets() {
    const barWidth =
      DATE_RANGE_TICK.day * MAX_DAYS_IN_MONTH -
      DATE_RANGE_TICK.day * this.xAxis.length;

    this.labelOffsets = [];
    let offsetDaysCount = 0;

    const offsets = this.xAxis.map((currentDate, index) => {
      const prevDate = this.xAxis[index - 1];
      const daysInMonth = dayjs(currentDate).daysInMonth();
      const daysInPrevMonth =
        index === 0 ? null : dayjs(prevDate).daysInMonth();

      if (daysInPrevMonth && daysInPrevMonth !== MAX_DAYS_IN_MONTH) {
        offsetDaysCount =
          offsetDaysCount + (MAX_DAYS_IN_MONTH - daysInPrevMonth);

        this.labelOffsets.push(
          offsetDaysCount + (MAX_DAYS_IN_MONTH - daysInMonth),
        );
        return (
          -barWidth / 2 +
          DATE_RANGE_TICK.day *
            (offsetDaysCount + (MAX_DAYS_IN_MONTH - daysInMonth))
        );
      }

      this.labelOffsets.push(offsetDaysCount);

      return -barWidth / 2 + DATE_RANGE_TICK.day * offsetDaysCount;
    });

    return { barWidth, offsets };
  }

  onPlotUpdate() {
    this.labelsOffsets();
  }

  labelsOffsets() {
    const self = this;

    const containerWidth = d3
      .select(this.containerTarget)
      .node()
      .getBoundingClientRect().width;

    d3.select(this.containerTarget)
      .selectAll('g.xtick')
      .each(function (_, i) {
        d3.select(this).attr(
          'transform',
          `translate(${self.labelOffsets[i] * (containerWidth / 360)}, 0)`,
        );
      });
  }
}
