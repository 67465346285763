import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
  static values = {
    url: String,
    dashboardId: Number,
  };

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.onEnd.bind(this),
      animation: 150,
    });
  }

  onEnd(event) {
    const card = event.item;
    const cardId = card.dataset.id;

    const oldIndex = event.oldIndex;
    const newIndex = event.newIndex;

    if (oldIndex === newIndex) {
      return;
    }

    fetch(`${this.urlValue}/${cardId}/change_position`, {
      method: 'PATCH',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': this.getMetaValue('csrf-token'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        position: newIndex,
        dashboard_id: this.dashboardIdValue,
      }),
    });
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute('content');
  }
}
