import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field', 'preview'];

  createPreview() {
    const form = this.element.closest('form');

    if (!form) return;
    const formData = new FormData(form);

    this.fieldTargets?.forEach((field) => {
      if (formData.get(field.dataset['field'])) {
        const locale = field.dataset['locale'];
        if (locale) {
          field.textContent =
            JSON.parse(locale)[formData.get(field.dataset['field'])];
        } else {
          field.textContent = formData.get(field.dataset['field']);
        }
      }
    });
  }
}
