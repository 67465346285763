import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

const STATES = {
  EXPANDED: 'expanded',
  COLLAPSED: 'collapsed',
};

const KEY = 'sidebar_state';

export default class extends Controller {
  static targets = ['submenu'];

  connect() {
    $('body').addClass('disable-animations');

    // $('aside a').on('click', () => {
    //   if (window.innerWidth < 1024) {
    //     this.toggle();
    //   }
    // })

    this.state = localStorage.getItem(KEY);

    if (!this.state) {
      this.state = STATES.EXPANDED;
      localStorage.setItem(KEY, this.state);
    }

    document.querySelector('body').dataset.sidebar = this.state;

    this.toggleSubmenu();

    // remove 'disable-animations' class from body
    // to show animation only after first load
    requestAnimationFrame(() => {
      $('body').removeClass('disable-animations');
    });
  }

  toggleSubmenu(open = true) {
    if (this.hasSubmenuTarget) {
      if (open) {
        const activeSubmenu = $(this.submenuTargets).filter(
          '[data-active=true]',
        );

        if (activeSubmenu.length > 0 && this.state === STATES.EXPANDED) {
          activeSubmenu[0].classList.add('open');
        }
      } else {
        $(this.submenuTargets).removeClass('open');
      }
    }
  }

  toggle() {
    switch (this.state) {
      case STATES.COLLAPSED: {
        this.state = STATES.EXPANDED;

        localStorage.setItem(KEY, STATES.EXPANDED);
        document.querySelector('body').dataset.sidebar = STATES.EXPANDED;

        this.toggleSubmenu();

        break;
      }
      default: {
        this.state = STATES.COLLAPSED;

        this.toggleSubmenu(false);
        localStorage.setItem(KEY, STATES.COLLAPSED);
        document.querySelector('body').dataset.sidebar = STATES.COLLAPSED;
      }
    }
  }
}
