import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['text', 'container'];
  static classes = ['scrolling'];

  static values = {
    duration: String,
  };

  connect() {
    if (this.hasContainerTarget) {
      this.observeContainerWidth();
    }

    const scrollingClass = this.scrollingClass;

    if (this.hasDurationValue) {
      $(this.textTargets).css('animation-duration', this.durationValue);
    }

    $(this.textTargets).each(function () {
      if (this.offsetWidth < this.scrollWidth) {
        $(this).addClass(scrollingClass);
      }
    });
  }

  observeContainerWidth() {
    let delay;

    const observer = new ResizeObserver(() => {
      clearTimeout(delay);
      delay = setTimeout(() => {
        const scrollingClass = this.scrollingClass;

        $(this.textTargets).each(function () {
          if (this.offsetWidth < this.scrollWidth) {
            $(this).addClass(scrollingClass);
          } else {
            $(this).removeClass(scrollingClass);
          }
        });
      }, 100);
    });

    observer.observe(this.containerTarget);
  }
}
