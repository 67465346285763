import dayjs from 'dayjs';
import $ from 'jquery';
import { Controller } from '@hotwired/stimulus';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD hh:mm:ss';
const DATE_PICKER_FORMAT = 'MM/DD/YYYY';
const DATE_PICKER_VIEW_FORMAT = 'MMM DD,YYYY';

export default class extends Controller {
  static targets = ['form'];

  static values = {
    min: String,
    max: String,
    value: String,
    name: String,
  };

  connect() {
    this.formTarget.addEventListener('formdata', (e) => {
      const formData = e.formData;
      const value = formData.get(this.nameValue);

      if (value) {
        const formatedValue = dayjs(value, DATE_PICKER_VIEW_FORMAT);

        formData.set(this.nameValue, formatedValue.endOf('day').toString());
      }
    });

    this.configCustomDateRange();
  }

  configCustomDateRange() {
    const datePicker = $(`input[name="${this.nameValue}"]`);

    datePicker.daterangepicker({
      opens: 'left',
      autoUpdateInput: false,
      singleDatePicker: true,
      startDate: dayjs(this.valueValue, DEFAULT_DATE_FORMAT).format(
        DATE_PICKER_FORMAT,
      ),
      minDate: dayjs(this.minValue, DEFAULT_DATE_FORMAT).format(
        DATE_PICKER_FORMAT,
      ),
      maxDate: dayjs(this.maxValue, DEFAULT_DATE_FORMAT).format(
        DATE_PICKER_FORMAT,
      ),
    });

    datePicker.val(
      dayjs(this.valueValue, DEFAULT_DATE_FORMAT).format(
        DATE_PICKER_VIEW_FORMAT,
      ),
    );

    datePicker.on('apply.daterangepicker', (ev, picker) => {
      const fromValue = picker.startDate.format(DATE_PICKER_VIEW_FORMAT);
      datePicker.val(fromValue);

      this.formTarget.requestSubmit();
    });
  }
}
