import TimeSeriesChartsController from './time_series_charts_controller.js';

// Required data for stacked area chart

// title - chart title

// traces (for stacked bar chart) - array

// xAxis - array of string/number/date to be displayed on the x-axis
// e.g. ["Product1", "Product2", "Product3"]

// yAxis - array of values for y axis or array of Hashes if traces provided
// e.g. ["Location1", "Location2"] or
// [{ trace1: value, trace2: value }, { trace1: value, trace2: value }]

export default class extends TimeSeriesChartsController {
  static targets = ['container', 'tooltip', 'legend'];

  static values = {
    title: { type: String, default: '' },
    yAxis: { type: Array, default: [] },
    xAxis: { type: Array, default: [] },
    traces: { type: Array, default: [] },
    options: { type: Object, default: {} },
    colorSet: { type: Array, default: [] },
  };

  connect() {
    super.connect();

    if (!this.yAxisValue.length || !this.xAxisValue.length) {
      this.drawEmptyResult();

      return;
    }

    const data = this.tracesValue.map((trace, index) => {
      const yAxis = this.yAxisValue.map((i) => i[trace]);

      return {
        x: this.xAxis,
        y: yAxis,
        initialName: trace,
        name: this.geTraceName(trace),
        visible: true,
        fill: this.optionsValue.color_filled ? 'tozeroy' : undefined,
        marker: {
          color: this.colorSetValue[index] || this.defaultColorSet[index],
        },
        stackgroup: this.isRelativeMode ? 'one' : '',
        groupnorm: this.isRelativeMode ? 'percent' : '',
        hoverinfo: 'none',
      };
    });

    this.drawChart(data, this.defaultTimeSeriesLayoutConfig);
  }
}
