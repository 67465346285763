import TimeSeriesChartsController from './time_series_charts_controller.js';
// Required data for stacked bar chart

// title - chart title

// traces (for stacked bar chart) - array

// xAxis - array of string/number/date to be displayed on the x-axis
// e.g. ["Product1", "Product2", "Product3"]

// yAxis - array of number or array of Hashes if traces provided
// e.g. ["Location1", "Location2"] or
// [{ trace1: value, trace2: value }, { trace1: value, trace2: value }]

export default class extends TimeSeriesChartsController {
  static targets = ['container', 'tooltip', 'legend'];

  static values = {
    yAxis: { type: Array, default: [] },
    xAxis: { type: Array, default: [] },
    traces: { type: Array, default: [] },
    options: { type: Object, default: {} },
    colorSet: { type: Array, default: [] },
  };

  connect() {
    super.connect();

    if (!this.yAxisValue.length || !this.xAxisValue.length) {
      this.drawEmptyResult();

      return;
    }

    const { barWidth, offsets } = this.calculateBarWidthAndOffsets();

    const data = this.tracesValue.map((trace, index) => {
      const yAxis = this.yAxisValue.map((i) => i[trace]);

      return {
        name: this.geTraceName(trace),
        type: 'bar',
        initialName: trace,
        orientation: this.orientation,
        visible: true,
        marker: {
          color: this.colorSetValue[index] || this.defaultColorSet[index],
        },
        hoverinfo: 'none',
        x: this.xAxis,
        y: yAxis,
        width: barWidth,
        offset: offsets,
        margin: { t: 0, b: 0, r: 0, l: 0, pad: 8 },
      };
    });

    this.drawChart(data, this.defaultTimeSeriesLayoutConfig);
  }
}
