import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

const DELAY = 1000;

export default class extends Controller {
  static targets = ['form', 'options'];

  connect() {
    this.timerId = null;

    this.formTarget.addEventListener('formdata', (e) => {
      const formData = e.formData;
      const data = Object.keys(Object.fromEntries(formData));

      if (!data.length) {
        formData.set('filters', []);
      }
    });
  }

  // reduction of requests number
  handleFilteringWithDetay() {
    clearTimeout(this.timerId);

    this.timerId = setTimeout(this.submitForm.bind(this), DELAY);
  }

  submitForm() {
    this.formTarget.requestSubmit();
  }

  resetAllFilters() {
    $(this.formTarget).find('input:checked').trigger('click');

    this.submitForm();
  }
}
