import { Controller } from '@hotwired/stimulus';
import Plotly from 'plotly.js-dist-min';

export default class extends Controller {
  static targets = ['draw'];

  connect() {
    this.url = this.data.get('url');
    this.height = this.data.get('height');
    this.width = this.data.get('width');
    this.barmode = this.data.get('barmode');

    fetch(this.url)
      .then((response) => response.json())
      .then((json) => {
        this.plot_timeseries(this.drawTarget, json);
      });
  }

  /*    Format fort multi group timeseries

        var trace1 = {
            x: ['giraffes', 'orangutans', 'monkeys'],
            y: [20, 14, 23],
            name: 'SF Zoo',
            type: 'bar'
        };

        var trace2 = {
            x: ['giraffes', 'orangutans', 'monkeys'],
            y: [12, 18, 29],
            name: 'LA Zoo',
            type: 'bar'
        }; */

  plot_timeseries(drawTarget, json) {
    const data = [];

    for (const k in json) {
      console.log('BUILD TRACE FOR K ' + k);
      console.log(json[k]);

      const x = [];
      const y = [];

      for (let i = 0; i < json[k].length; i++) {
        x.push(json[k][i][0]); // json[k] is array of time series nodes - [0] the time
        y.push(json[k][i][1]); // json[k] is array of time series nodes - [1] the node value at that moment in time
      }

      console.log('TRACE x ' + x);
      console.log('TRACE y ' + y);

      const trace = {
        x,
        y,
        name: k,
        type: 'bar',
        width: 1,
      };
      data.push(trace);
    }

    const layout = {
      bargap: 0.1,
      barmode: this.barmode,
      xaxis: {
        tickangle: -45,
      },
      yaxis: {
        zeroline: false,
        gridwidth: 2,
      },
      width: this.width,
      height: this.height,
      font: {
        family:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        size: 12,
        color: '#7f7f7f',
      },
      paper_bgcolor: '#151a27',
    };

    const config = { displayModeBar: false };

    Plotly.newPlot(drawTarget, data, layout, config);
  }
}
