// app/javascript/controllers/jurisdictions_controller.js
import { Controller } from '@hotwired/stimulus';
import { PULSE_API_ROOT } from './constants';

export default class extends Controller {
  static targets = [
    'countries',
    'jurisdiction',
    'permission',
    'regulation',
    'collectionPoint',
  ];

  connect() {
    if (this.hasCollectionPointTarget) {
      this.updateCollectionPoint();
    }

    if (this.hasCountriesTarget) {
      this.updateCountries();
    }

    if (this.hasRegulationTarget) {
      this.updateRegulations();
    }

    if (this.hasPermissionsTarget) {
      this.updatePermissions();
    }
  }

  updateCollectionPoint() {
    const selectedJurisdictionId = this.jurisdictionTarget.value;

    let url = `${PULSE_API_ROOT}/collection_points.json?jurisdiction_id=${selectedJurisdictionId}&select_options=true`;

    if (this.hasRegulationTarget) {
      url = `${url}&regulation_id=${this.regulationTarget.value}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        this.collectionPointTarget.replaceChildren();
        data.forEach((item, index) => {
          const option = document.createElement('option');
          option.text = item[0];
          option.value = item[1];
          this.collectionPointTarget.add(option);

          if (index === 0) {
            option.selected = true;
          }
        });

        const event = new CustomEvent('collectionPointUpdated');
        this.collectionPointTarget.dispatchEvent(event);
      });
  }

  updateCountries() {
    const selectedJurisdictionId = this.jurisdictionTarget.value;

    fetch(
      `/api/v1/jurisdictions/${selectedJurisdictionId}/countries.json?select_options=true`,
    )
      .then((response) => response.json())
      .then((data) => {
        this.countriesTarget.replaceChildren();
        data.forEach((item) => {
          const option = document.createElement('option');
          option.text = item[1];
          option.value = item[0];
          this.countriesTarget.add(option);
        });
      });
  }

  updatePermissions() {
    if (this.hasPermissionTarget) {
      const selectedJurisdictionId = this.jurisdictionTarget.value;

      fetch(
        `/api/v1/permissions.json?jurisdiction_id=${selectedJurisdictionId}&collection_point_id=${this.collectionPointTarget.value}&select_options=true`,
      )
        .then((response) => response.json())
        .then((data) => {
          this.permissionTarget.replaceChildren();
          data.forEach((item) => {
            const option = document.createElement('option');
            option.text = item[0]; // short_description
            option.value = item[1]; // id
            this.permissionTarget.add(option);
          });
        });
    }
  }

  updateRegulations() {
    const selectedJurisdictionId = this.jurisdictionTarget.value;
    fetch(`/api/v1/jurisdictions/${selectedJurisdictionId}/regulations.json`)
      .then((response) => response.json())
      .then((data) => {
        this.regulationTarget.replaceChildren();
        data.forEach((item) => {
          const option = document.createElement('option');
          option.text = `${item.short_name} (${item.name})`;
          option.value = item.id;
          this.regulationTarget.add(option);
        });

        const event = new CustomEvent('regulationUpdated');
        this.regulationTarget.dispatchEvent(event);
      });
  }
}
