import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['tab', 'frame', 'content'];
  static classes = ['active'];

  static values = {
    active: String,
  };

  connect() {
    this.selectedUrl = null;

    $(this.defaultTab).click();
  }

  get defaultTab() {
    if (this.activeValue) {
      return (
        $(this.tabTargets).filter(`[data-tab-name='${this.activeValue}']`)[0] ||
        this.tabTargets[0]
      );
    }

    return this.tabTargets[0];
  }

  toggleFrame(e) {
    const targetUrl = e.currentTarget.dataset.toggleUrl;

    if (this.selectedUrl === targetUrl) {
      return;
    }

    this.selectedUrl = targetUrl;

    if (this.activeClasses) {
      const classString = this.activeClasses.join(' ');

      $(this.tabTargets).removeClass(classString);
      $(e.currentTarget).addClass(classString);
    }

    this.frameTarget.src = targetUrl;
    this.frameTarget.id = e.currentTarget.dataset.toggleTarget;

    this.frameTarget.reload();
  }

  toggleVisibility(e) {
    const targetTab = e.currentTarget.dataset.tab;

    if (this.activeClasses) {
      const classString = this.activeClasses.join(' ');

      $(this.tabTargets).removeClass(classString);
      $(e.currentTarget).addClass(classString);
    }

    const targetContent = this.contentTargets.find(
      (c) => c.dataset.tab === targetTab,
    );

    $(this.contentTargets).hide();
    $(targetContent).show();
  }
}
